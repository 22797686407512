import React, {forwardRef} from 'react';
import {Table} from "antd";

const addIndex = (data = []) => {
    const newData = [];
    data.forEach((item, i) => {
        newData[i] = {...item, index: i + 1}
    });
    return newData;
};

export default forwardRef(({value = [], notAddIndex, ...otherProps}, ref) => (
    <Table dataSource={notAddIndex ? value : addIndex(value)} className="fixedWidthTable" size="small" bordered
           pagination={false} rowKey="index" {...otherProps}/>
));