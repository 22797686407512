import React from 'react';
import loadingImg from '../../res/img/loading_black.gif';
import successImg from '../../res/img/svgIcon/success.svg';
import warningImg from '../../res/img/svgIcon/warning.svg';
import errorImg from '../../res/img/svgIcon/error.svg';
import store from '../../store.js';
import {connect} from 'react-redux';
import {stateToProps} from '../tools';

const CustomTip = ({custiptxt, custiptype, custipshow}) => {
	let src = null;
	let width = custiptxt ? custiptxt.length * 14 + 26 + 20 + 'px' : '0px'
	switch(custiptype){
		case 'loading':
			src = loadingImg; break;
		case 'success':
			src = successImg; break;
		case 'warning':
			src = warningImg; break;
		case 'error':
			src = errorImg; break;
	}
	
	return (
		<div id='customtip' style={{'display': custipshow ? 'block' : 'none'}}>
			<div style={{'width': width}}>
				<img src={src}/>
				{custiptxt}
			</div>
		</div>
	)
}

const mapStateToProps = stateToProps(({state}) => ({
	custiptxt: state.custiptxt,
	custiptype: state.custiptype,
	custipshow: state.custipshow,
}));

export default connect(mapStateToProps)(CustomTip);