import {useEffect} from 'react';
import {populatePermission} from '../tools';
import {useCookies} from 'react-cookie';

const base = (fn) => {

    const state = {};

    return (props, ref) => {
	
		const [cookies,] = useCookies(['admin_token']);
        useEffect(() => {
            if (props.onInit) {
				props.onInit(props, cookies.admin_token);
                populatePermission(props.group);
            }
            if (props.onRemove) return () => props.onRemove(props);
        }, []);

        props.onUpdate && useEffect(() => {
            props.onUpdate(props, state);
			populatePermission(props.group);
        });
		
        return fn(props, state);
    }

};

export default base;