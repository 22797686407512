export const TOKEN = 'token';
export const STAFF = 'staff';
export const CLEAR = 'clear';
export const SELMENU = 'selMenu';
export const ENTERMENU = 'enterMenu';
export const LEAVEMENU = 'leaveMenu';
export const SELSUBMENU = 'selSubMenu';
export const SELTAB = 'selTab';
export const CLSUBMENU = 'clSubMenu';
export const MOVE = 'move';
export const SEL = 'sel';
export const TOGGLE = 'toggle';
export const TOGGLETAB = 'toggleTab';
export const CLALLTAB = 'clAllTab';
export const CLOTHERTAB = 'clOtherTab';
export const CLOSETABBUT = "closeTabBut";
export const NEWTAB = "newTab";
//浏览器大小发生改变时
export const WINDOWRESIZE = 'windowResize';
//控制操作栏更多部分的伸展
export const ACTIONBAR = 'actionBar';
//搜索框和高级搜索的参数
export const PARAMS = 'params';
// 更多选项相关
export const SHOWMORE = 'showMore';
export const SELECTMORE = 'selectMore';
export const RESTORE_TAB = 'restoreTab';
export const SET_OSS_DATA = "setOssData";