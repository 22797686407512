const env = "test";

export default (() => {

	const urls = env === 'dev' ? {
		supervision : "http://127.0.0.1:8447",
		// supervision : "http://192.168.123.190:8447",
		//supervision : "http://47.106.221.166:8447",
		idCenter : "http://127.0.0.1:8444",
		// idCenter : "http://192.168.123.190:8444",
		// idCenter : "http://192.168.123.76:8444",
		// idCenter : "http://120.78.126.154:8444",
		certUrl : "http://127.0.0.1:8448",
		monitor : "http://127.0.0.1:8442",
		// monitor : "http://192.168.123.76:8442",
		contractImageUrl : "http://120.78.126.154:8030/conImage/",
		adjustImage : "http://120.78.126.154:8030/adjustImage/",
		potImage : "http://120.78.126.154:8030/potImage/",
		jzImage : "http://120.78.126.154:8030/jzImage/",
		order :"http://webapi.sdhoo.me",
		claim:"http://127.0.0.1:8445",
		loanCenter :"http://127.0.0.1:8083",
		log : "http://127.0.0.1:8081",
		messenger : "ws://msg.sdhoo.me:8089",
		mer : "http://mermgr.sdhoo.me",
		web : "http://127.0.0.1:8075",
		electrct:"http://127.0.0.1:6066",
		domain : ".sdhoo.me",
		ua1 : "6447235186692",
		ua2 : "6546441912321",
		oa1 : "6457559007234",
		oa2 : "6459085520897",
		reportFilePath : "http://120.78.126.154:8030/reportFile/",
		region: 'oss-cn-shenzhen',
		htp: 'http://',
		certFolder: 'etc/pki/CA',
		logoBucket : 'zr-slhk-logo',
	} : env === 'test' ? {
		supervision : "http://120.78.126.154:8445",
		idCenter : "http://120.78.127.133:8444",
		certUrl : "http://127.0.0.1:8448",
		monitor : "http://127.0.0.1:8442",
		contractImageUrl : "http://120.78.126.154:8030/conImage/",
		adjustImage : "http://120.78.126.154:8030/adjustImage/",
		potImage : "http://120.78.126.154:8030/potImage/",
		jzImage : "http://120.78.126.154:8030/jzImage/",
		order :"http://webapi.sdhoo.me",
		claim:"http://127.0.0.1:8445",
		loanCenter :"http://127.0.0.1:8083",
		log : "http://120.78.127.133:8081",
		messenger : "ws://msg.sdhoo.me:8089",
		mer : "http://mermgr.sdhoo.me",
		web : "http://127.0.0.1:8075",
		electrct:"http://127.0.0.1:6066",
		domain : ".sdhoo.me",
		ua1 : "6447235186692",
		ua2 : "6546441912321",
		oa1 : "6457559007234",
		oa2 : "6459085520897",
		reportFilePath : "http://120.78.126.154:8030/reportFile/",
		region: 'oss-cn-shenzhen',
		htp: 'http://',
		certFolder: 'etc/pki/CA',
		logoBucket : 'zr-slhk-logo',
	} :{
		supervision : "http://120.78.127.133:8447",
		idCenter : "http://120.78.127.133:8444",
		certUrl : "http://127.0.0.1:8448",
		monitor : "http://127.0.0.1:8442",
		contractImageUrl : "http://120.78.126.154:8030/conImage/",
		adjustImage : "http://120.78.126.154:8030/adjustImage/",
		potImage : "http://120.78.126.154:8030/potImage/",
		jzImage : "http://120.78.126.154:8030/jzImage/",
		order :"http://webapi.sdhoo.me",
		claim:"http://127.0.0.1:8445",
		loanCenter :"http://127.0.0.1:8083",
		log : "http://127.0.0.1:8081",
		messenger : "ws://msg.sdhoo.me:8089",
		mer : "http://mermgr.sdhoo.me",
		web : "http://127.0.0.1:8075",
		electrct:"http://127.0.0.1:6066",
		domain : ".sdhoo.me",
		ua1 : "6447235186692",
		ua2 : "6546441912321",
		oa1 : "6457559007234",
		oa2 : "6459085520897",
		reportFilePath : "http://120.78.126.154:8030/reportFile/",
		region: 'oss-cn-shenzhen',
		htp: 'http://',
		certFolder: 'etc/pki/CA',
		logoBucket : 'zr-slhk-logo',
	};

	return {
		"urls": urls,
		"fail": "err"
	}
})();
