export const comment = /^.{1,140}$/;
export const username = /^[a-zA-Z0-9\u4e00-\u9fa5-_]{2,15}$/;
export const name = {pattern: /^[a-zA-Z0-9\u4e00-\u9fa5()_（）、]+$/, message: "名称只能是中英文数字()_、"};
export const code = {pattern: /^\w{2,30}$/, message: "编码必须是英文数字,且长度在2~30之间"};
export const email = {pattern: /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/, message: "邮箱格式错误"};
export const mobile = {pattern: /^\d{11}$/, message: "手机号码必填且只能是11位整数"}
export const password = {pattern: /^[a-zA-Z0-9]{6,20}$/, message: "密码必须是英文或数字，且长度在6~20之间"};
export const tag = {pattern: /^[a-zA-Z0-9]{1,20}$/, message: "名称只能是英文字符和数字"};
export const ipCode = {pattern: /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/, message: "ip格式错误"};
export const rate3 = {pattern: /^\d{1,6}(\s*|\.\d{1,1})$/, message : "数字格式错误，只能输入小数点前六位与后一位"};
export const rate5 = {pattern: /^[0-9]{0,4}$/, message : "数字格式错误，只能输入10000以内的正整数"};