import {get} from '../../middlewares/request';
//对象 => key & value的方法
const obj2kv = (data) => {
    const paramsArray = []; //拼接参数
    Object.keys(data).forEach(key => {
        paramsArray.push(key + '=' + data[key]);
    });

    return paramsArray.length > 0 ? paramsArray.join('&') : '';
}
//以下两个key是在高德地图服务内申请的
//用来调用高德地图服务的key
export const AMAP_WEB_SERVER_KEY = '0947a3028f79b90635c25b3a4b155060';
//用来调用高德地图组件的key
export const AMAP_WEB_COMPONENT_KEY = '5d49467600da831cac8d9188838f1100';

//逆地理解析地址
export const getAddressRegeo = (params) => {
    const data = obj2kv(params);
    return fetch(`https://restapi.amap.com/v3/geocode/regeo?key=${AMAP_WEB_SERVER_KEY}&${data}`)
        .then(resp => Promise.all([resp.status, resp.json()])).then((resp) => {
            if(resp[0] !== 200) {
                return;
            }else{
                return resp[1]
            }
        })
        .catch(e=>console.log(e));
}

//搜周边
export const getRound = (params) => {
    const data = obj2kv(params);
    return fetch(`https://restapi.amap.com/v3/place/around?key=${AMAP_WEB_SERVER_KEY}&${data}`)
        .then(resp => Promise.all([resp.status, resp.json()])).then((resp) => {
            if(resp[0] !== 200) {
                return;
            }else{
                return resp[1]
            }
        })
        .catch(e=>console.log(e));
}

//地理解析地址
export const getAddressGeo = (params) => {
    const data = obj2kv(params);
    return fetch(`https://restapi.amap.com/v3/geocode/geo?key=${AMAP_WEB_SERVER_KEY}&${data}`)
        .then(resp => Promise.all([resp.status, resp.json()])).then((resp) => {
            if(resp[0] !== 200) {
                return;
            }else{
                return resp[1]
            }
        })
        .catch(e=>console.log(e));
}
