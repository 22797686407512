import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {renderRoutes} from "react-router-config";
import {ConnectedRouter} from 'connected-react-router';
import store, {history} from './store.js';
import routes from './routes';
import {ConfigProvider} from 'antd';

import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

/*
import Home from './home.js';
import Hnt from './mgr_hnt/mgr_hnt.js';
import Lab from './lab/lab.js';
*/
//const opt = {ssr:false};
//const Home = loadable(() => import(/* webpackChunkName: "home" */'./home.js'));

ReactDOM.render(
	<ConfigProvider locale={zhCN}>
		<Provider store={store}>
			<ConnectedRouter history={history}>
				{renderRoutes(routes)}
			</ConnectedRouter>
		</Provider>
	</ConfigProvider>,
  document.getElementById('root')
);
