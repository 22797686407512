import React from 'react';
import {Line} from 'react-chartjs-2';

const setLineModuleData = (params, data) =>{
    let xData = [], dataSets = [], tempyData = [], lineModule, labelName, color = setColor(), colorLength = color.length, random = Math.floor(Math.random() * colorLength);
    if(data && data.length > 0){
        xData = setXData(data);
        for(let i = 0; i < data.length; i++){
            labelName = params[i] ? params[i] : "";
            for(let j = 0; j < data[i].length; j++){
                tempyData.push(data[i][j]);
            }
            dataSets[i] = {
                label : labelName,

                fill: false,
                lineTension: 0.1,
                backgroundColor: i < colorLength ? color[i] : color[random],
                borderColor: i < colorLength ? color[i] : color[random],
                // borderColor: 'rgb(204,204,204)',//边框颜色
                // borderWidth:10,//线条宽度
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: i < colorLength ? color[i] : color[random],//点周边颜色
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: i < colorLength ? color[i] : color[random],
                pointHoverBorderColor: i < colorLength ? color[i] : color[random],
                pointHoverBorderWidth: 2,
                pointRadius: 5,
                pointHitRadius: 10,

                data: tempyData
            };
            tempyData = [];
        }
    }
    lineModule = {
        labels: xData,
        datasets: dataSets
    };

    return lineModule;
};

const setXData = (data) =>{
    let xDate = [];
    if(!data || data.length == 0){
        return "";
    }
    for(let i = 0; i < data.length; i++){
        for(let j = 0; j < data[i].length; j++){
            if(xDate.indexOf(data[i][j].x) == -1){
                xDate.push(data[i][j].x);
            }
        }
    }
    if(xDate.length <= 1){
        xDate.push("");
    }

    return xDate;

    // let length = 0, index = 0, xData = [];
    // for(let i = 0; i < data.length; i++){
    //     if(data[i].length > length){
    //         length = data[i].length;
    //         index = i;
    //     }
    // }
    // for(let j = 0; j < data[index].length; j++){
    //     xData.push(data[index][j].x);
    // }
    // return xData;
};

const setColor = () => {
    return ["#00ffff","#000000","#0000ff","#a52a2a","#00008b","#008b8b","#a9a9a9","#006400","#bdb76b","#8b008b","#556b2f","#ff8c00","#9932cc","#8b0000","#e9967a","#9400d3","#ff00ff", "#ffd700","#008000","#4b0082","#f0e68c","#add8e6","#90ee90", "#d3d3d3","#ffb6c1","#00ff00","#ff00ff","#800000","#000080","#808000","#ffa500","#ffc0cb","#800080","#800080","#ff0000","#c0c0c0","#ffff00"];
};

const LineChart = props => {

    const {params, data, title = "title", xAxes = "x轴", yAxes = "y轴"} = props;

    const sourceData = setLineModuleData(params, data);

    const options = {
        maintainAspectRatio : false,
        legend: {													// 是否配置图例
            display: true,											// 图例是否显示
            position: 'bottom',										// 图例出现的位置
            labels: {
                boxWidth: 30,										// 图例的图标宽度
                fontColor: 'black',									// 图例字体颜色
                fontSize: 20,										// 图例文字大小
                padding: 20,										// 图例区域的内边距
            }
        },
        scales: {
            xAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: xAxes,
                    lineHeight: 0,
                },
                ticks: {
                    autoSkip: true,
                    autoSkipPadding: 20,
                }
            }],
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: yAxes,
                    lineHeight: 0,
                },
                ticks: {
                    min: 0
                }
            }]
        },
        title: {
            display: true,											// 是否启用标题
            text: title,						                    // 可以是‘’形式的文字行，也可以是数组，数组情况下会分成多行展示
            position: 'top',										// 标题的位置
            fontSize: 20,											// 标题文字大小
            padding: 20,											// 标题上下边距
            lineHeight: 1.2,										// 标题行高
        },
        layout: {													// 图表本身的布局设计
            padding: {
                left: 70,
                right: 70,
                top: 0,
                bottom: 0
            }
        },
        tooltips: {
            enabled: true,											// 是否开启提示
            /*backgroundColor: 'blue',								// 标题的背景颜色
            titleFontSize: 15,										// 提示标题的字体大小
            titleFontColor: 'red',									// 提示标题的字体颜色
            callbacks: {
                labelColor: function(tooltipItem, chart) {
                    return {
                        borderColor: 'rgb(255, 0, 0)',
                        backgroundColor: 'rgb(255, 0, 0)'
                    }
                },
                labelTextColor:function(tooltipItem, chart){
                    return '#543453';
                }
            }*/
        },
    };

    return (
        <div>
            <Line data={sourceData} height={400} options={options}/>
        </div>
    );
};

export default LineChart;