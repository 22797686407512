import React, {useEffect} from 'react';
import {Tree} from '../../index';
import {dispatchToProps, stateToProps} from "../../tools";
import {connect} from "react-redux";
import {close, treeInfo} from "../../actions";

const getLabel = (nodes) => {
    const l = nodes.reduce((total, currentValue) => total + ", " + currentValue.title, "");
    return l.length > 2 ? l.substring(2, l.length) : "";
};

// 树，子项横过来的
const TreeField = ({onChange, value, checkedNodes, checkable = true, onCheck, onInit, onSelect, ...otherProps}) => {

    useEffect(() => {
        !checkedNodes && value && onInit();
    }, [value]);

    const select = checkable ? {onCheck, checkedKeys: value} : {onSelect, selectedKeys: value};

	return <Tree
        checkable={checkable}//默认多选
        {...otherProps}
        {...select}
    />
};

const mapStateToProps = stateToProps(({ownProps: {name}, state: {treeInfo}}) => ({
	checkedNodes: treeInfo && treeInfo[name] && treeInfo[name].checkedNodes,
}));

const mapDispatchToProps = dispatchToProps(({dispatch, ownProps: {onChange, name, isObj}, store, sk}) => ({

    onCheck: (checkedKeys, {checkedNodes}) => {
        isObj && dispatch(treeInfo(name, {checkedNodes}));
        onChange(checkedKeys);
        const label = getLabel(checkedNodes);
        dispatch(treeInfo(name, {label, searchLabel: label}));
    },

    onSelect: (selectedKeys, {selectedNodes}) => {
        isObj && dispatch(treeInfo(name, {checkedNodes: selectedNodes}));
        onChange(selectedKeys);
        const label = getLabel(selectedNodes);
        dispatch(treeInfo(name, {label, searchLabel: label}));
        dispatch(close(name))
    },

    onInit: () => {
        const state = store.getState()[sk];
        const checkedNodes = [];

        if (state[name] && state[name][0]) {
            const stack = [];
            stack.push(state[name][0]);
            state[name][0].checked && checkedNodes.push(state[name][0].key);

            let node = null;
            while ((node = stack.pop())) {
                if (node.children && node.children.length > 0) {
                    node.children.forEach(n => {
                        n.checked && checkedNodes.push(n);
                        stack.push(n);
                    });
                }
            }
        }

        isObj && dispatch(treeInfo(name, {checkedNodes}));
    }
}));

export default connect(mapStateToProps, mapDispatchToProps)(TreeField);
