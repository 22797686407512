import React from 'react';
import {connect} from 'react-redux';
import {sel, showMore} from '../../../actions';

const Item = ({moIcon, moName, onSelect, className, type, moreactive, onShowMore}) => {
	const cl = "iconfont " + moIcon;
	if(type === 'normal'){ 
		return (
			<li className={className} onClick={onSelect} >
				<a href="#">
					<span className={cl}></span>
					<span className="word">{moName}</span>
				</a>
			</li>
		);
	}else{
		return (
			<li id="btnMore" onClick={onShowMore} className={moreactive ? 'active' : ''}>
				<a>
					<img src={require('./../../../res/img/svgIcon/more.svg')} />
					<span className="word">更多</span>
					<span className='iconfont icon-zhedie' style={{'marginLeft':'10px','marginRight':'0px'}}></span>
				</a>
			</li>
		)
	}
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	onSelect: () => {
		dispatch(sel(ownProps.moId))
	},
	onShowMore: () => {
		const left = document.getElementById('btnMore').offsetLeft;
		dispatch(showMore(ownProps.moremenu.slice(0, ownProps.morelength), left));
	}
});

export default connect(null, mapDispatchToProps)(Item);