import loadable from "@loadable/component";
import resetStore from './enhancer/resetStore';
import Content from './html/content';
import ViewRouter from './html/viewRouter';

const routes = [
    {
        path: "/",
        exact: true,
        component: loadable(() => {
            return resetStore(import(/* webpackChunkName: "home" */'./html/home'));
        })
    },
    {
        path: "/main",
        component: loadable(() => {
            return resetStore(import(/* webpackChunkName: "layout" */'./html/layout'));
        }),
        routes: [
            {
                path: "/main/content",
                component: Content
            },
            {
                path: "/main/perm",
                component: ViewRouter,
                routes: [
                    {
                        path: "/main/perm/link",
                        component: loadable(() => (
                            import(/* webpackChunkName: "link" */'./html/perm/link')
                        ))
                    },
                    {
                        path: "/main/perm/settingNote",
                        component: loadable(() => (
                            import(/* webpackChunkName: "settingNote" */'./html/perm/settingNote')
                        ))
                    },
                    {
                        path: "/main/perm/menu",
                        component: loadable(() => (
                            import(/* webpackChunkName: "menu" */'./html/perm/menu')
                        ))
                    },
                    {
                        path: "/main/perm/role",
                        component: loadable(() => (
                            import(/* webpackChunkName: "role" */'./html/perm/role')
                        ))
                    },
                    {
                        path: "/main/perm/districtOrgan",
                        component: loadable(() => (
                            import(/* webpackChunkName: "districtOrgan" */'./html/perm/districtOrgan')
                        ))

                    },
                    {
                        path: "/main/perm/staff",
                        component: loadable(() => (
                            import(/* webpackChunkName: "staff" */'./html/perm/staff')
                        ))
                    }
                ]
            },
			{
                path: "/main/workflow",
                component: ViewRouter,
                routes: [
                    {
                        path: "/main/workflow/form",
                        component: loadable(() => (
                            import(/* webpackChunkName: "form" */'./html/workflow/form')
                        ))
                    },
					{
                        path: "/main/workflow/prop",
                        component: loadable(() => (
                            import(/* webpackChunkName: "prop" */'./html/workflow/prop')
                        ))
                    }
                ]
            },
            {
                path: "/main/hnt",
                component: ViewRouter,
                routes: [
                    {
                        path: "/main/hnt/hntRawMaterial",
                        component: loadable(() => (
                            import(/* webpackChunkName: "hntRawMaterial" */'./html/mgr_hnt/hntRawMaterial')
                        )),
                    },
                    {
                        path: "/main/hnt/hntContract",
                        component: loadable(() => (
                            import(/* webpackChunkName: "hntContract" */'./html/mgr_hnt/hntContract')
                        ))
                    },
                    {
                        path: "/main/hnt/hntDesignMix",
                        component: loadable(() => (
                            import(/* webpackChunkName: "hntMix" */'./html/mgr_hnt/hntMix')
                        )),
                    },
                    {
                        path: "/main/hnt/hntMixAdd",
                        component: loadable(() => (
                            import(/* webpackChunkName: "hntMixAdd" */'./html/mgr_hnt/hntMixAdd')
                        ))
                    },
                    {
                        path: "/main/hnt/hntMixDetail",
                        component: loadable(() => (
                            import(/* webpackChunkName: "hntMixDetail" */'./html/mgr_hnt/hntMixDetail')
                        )),
                    },
                    {
                        path: "/main/hnt/hntProductionTask",
                        component: loadable(() =>
                            import(/* webpackChunkName: "hntProductionTask" */'./html/mgr_hnt/hntProductionTask')
                        )
                    },
                    {
                        path: "/main/hnt/hntProductionTaskDetail",
                        component: loadable(() => (
                            import(/* webpackChunkName: "hntProductionTaskDetail" */'./html/mgr_hnt/hntProductionTaskDetail')
                        )),
                    },
                    {
                        path: "/main/hnt/hntRecordTask",
                        component: loadable(() => (
                            import(/* webpackChunkName: "hntRecordTask" */'./html/mgr_hnt/hntRecordTask')
                        )),
                    },
                    {
                        path: "/main/hnt/hntRecordTaskAdd",
                        component: loadable(() => (
                            import(/* webpackChunkName: "hntRecordTaskAdd" */'./html/mgr_hnt/hntRecordTaskAdd'))
                        ),
                    },
                    {
                        path: "/main/hnt/hntRecordTaskDetail",
                        component: loadable(() => (
                            import(/* webpackChunkName: "hntRecordTaskDetail" */'./html/mgr_hnt/hntRecordTaskDetail')
                        )),
                    },
                    {
                        path: "/main/hnt/hntProduceData",
                        component: loadable(() => (
                            import(/* webpackChunkName: "hntProduceData" */'./html/mgr_hnt/hntProduceData')
                        )),
                    },
                    {
                        path: "/main/hnt/hntProduceDataDetail",
                        component: loadable(() => (
                            import(/* webpackChunkName: "hntProduceDataDetail" */'./html/mgr_hnt/hntProduceDataDetail')
                        )),
                    },
                ]
            },
            {
                path: "/main/jz",
                component: ViewRouter,
                routes: [
                    {
                        path: "/main/jz/noRecordPile",
                        component: loadable(() => (
                            import(/* webpackChunkName: "noRecordPile" */'./html/mgr_jz/noRecordPile')
                        ))
                    },
                    {
                        path: "/main/jz/pileExtDetail",
                        component: loadable(() => (
                            import(/* webpackChunkName: "pileExtDetail" */'./html/mgr_jz/pileExtDetail')
                        ))
                    },
                    {
                        path: "/main/jz/pileQsChart",
                        component: loadable(() => (
                            import(/* webpackChunkName: "pileQsChart" */'./html/mgr_jz/pileQsChart')
                        ))
                    },
                    {
                        path: "/main/jz/pileWyChart",
                        component: loadable(() => (
                            import(/* webpackChunkName: "pileWyChart" */'./html/mgr_jz/pileWyChart')
                        ))
                    }
                ]
            },
            {
                path: "/main/lab",
                component: ViewRouter,
                routes: [
                    {
                        path: "/main/lab/labProject",
                        component: loadable(() => (
                            import(/* webpackChunkName: "labProject" */'./html/mgr_lab/labProject')
                        ))
                    },
                    {
                        path: "/main/lab/labDelegate",
                        component: loadable(() => (
                            import(/* webpackChunkName: "labDelegate" */'./html/mgr_lab/labDelegate'))
                        )
                    },
                    {
                        path: "/main/lab/labSample",
                        component: loadable(() => (
                            import(/* webpackChunkName: "labSample" */'./html/mgr_lab/labSample'))
                        )
                    },
                    {
                        path: "/main/lab/labSampleDetail",
                        component: loadable(() => (
                            import(/* webpackChunkName: "labSampleDetail" */'./html/mgr_lab/labSampleDetail'))
                        )
                    },
                    {
                        path: "/main/lab/labReport",
                        component: loadable(() => (
                            import(/* webpackChunkName: "labReport" */'./html/mgr_lab/labReport'))
                        )
                    },
                    {
                        path: "/main/lab/labReportDetail",
                        component: loadable(() => (
                            import(/* webpackChunkName: "labReportDetail" */'./html/mgr_lab/labReportDetail'))
                        )
                    },
                    {
                        path: "/main/lab/labReportFile",
                        component: loadable(() => (
                            import(/* webpackChunkName: "labReportFile" */'./html/mgr_lab/labReportFile'))
                        )
                    },
                    {
                        path: "/main/lab/lineChart",
                        component: loadable(() => (
                            import(/* webpackChunkName: "labLineChart" */'./html/mgr_lab/lineChart'))
                        )
                    },
                ]
            },
            {
                path: "/main/common",
                component: ViewRouter,
                routes: [
                    {
                        path: "/main/common/modifyTrack",
                        component: loadable(() => (
                            import(/* webpackChunkName: "modifyTrack" */'./html/mgr_common/modifyTrack')
                        ))
                    },
                    {
                        path: "/main/common/logoSetting",
                        component: loadable(() => (
                            import(/* webpackChunkName: "logoSetting" */'./html/mgr_common/logoSetting')
                        ))
                    },
                ]
            },
            {
                path: "/main/jdh",
                component: ViewRouter,
                routes: [
                    {
                        path: "/main/jdh/jdhAccount",
                        component: loadable(() => (
                            import(/* webpackChunkName: "jdhAccount" */'./html/mgr_jdh/jdhAccount'))
                        ),
                    },
                    {
                        path: "/main/jdh/jdhState",
                        component: loadable(() => (
                            import(/* webpackChunkName: "jdhState" */'./html/mgr_jdh/jdhState'))
                        )
                    },
                    {
                        path: "/main/jdh/jdhRemote",
                        component: loadable(() => (
                            import(/* webpackChunkName: "jdhRemote" */'./html/mgr_jdh/jdhRemote'))
                        ),
                    }
                ]
            },
            {
                path: "/main/inte",
                component: ViewRouter,
                routes: [
                    {
                        path: "/main/inte/client",
                        component: loadable(() => (
                            import(/* webpackChunkName: "client" */'./html/mgr_inte/client')
                        ))
                    },
                    {
                        path: "/main/inte/clientCategory",
                        component: loadable(() => (
                            import(/* webpackChunkName: "clientCategory" */'./html/mgr_inte/clientCategory')
                        ))
                    },
                    {
                        path: "/main/inte/clientLink",
                        component: loadable(() => (
                            import(/* webpackChunkName: "clientLink" */'./html/mgr_inte/clientLink')
                        ))
                    },
                    {
                        path: "/main/inte/clientLinkInstanceAdd",
                        component: loadable(() => (
                            import(/* webpackChunkName: "clientLinkInstanceAdd" */'./html/mgr_inte/clientLinkInstanceAdd')
                        ))
                    },
                    {
                        path: "/main/inte/clientLinkInstanceDetail",
                        component: loadable(() => (
                            import(/* webpackChunkName: "clientLinkInstanceDetail" */'./html/mgr_inte/clientLinkInstanceDetail')
                        ))
                    },
                ]
            },
            {
                path: "/main/log",
                component: ViewRouter,
                routes: [
                    {
                        path: "/main/log/logs",
                        component: loadable(() => (
                            import(/* webpackChunkName: "logs" */'./html/mgr_log/logs')
                        ))
                    },
                    {
                        path: "/main/log/audit",
                        component: loadable(() => (
                            import(/* webpackChunkName: "audit" */'./html/mgr_log/audit')
                        ))
                    },
                    {
                        path: "/main/log/auditLog",
                        component: loadable(() => (
                            import(/* webpackChunkName: "auditLog" */'./html/mgr_log/auditLog')
                        ))
                    },
                    {
                        path: "/main/log/moduleLog",
                        component: loadable(() => (
                            import(/* webpackChunkName: "moduleLog" */'./html/mgr_log/moduleLog')
                        ))
                    }
                ]
            },
            {
                path: "/main/monitor",
                component: ViewRouter,
                routes: [
                    {
                        path: "/main/monitor/monitorType",
                        component: loadable(() => (
                            import(/* webpackChunkName: "monitorType" */'./html/mgr_monitor/monitorType')
                        ))
                    },
                    {
                        path: "/main/monitor/sysMonitor",
                        component: loadable(() => (
                            import(/* webpackChunkName: "sysMonitor" */'./html/mgr_monitor/sysMonitor')
                        ))
                    }
                ]
            },
            {
                path: "/main/timer",
                component: ViewRouter,
                routes: [
                    {
                        path: "/main/timer/sysTimer",
                        component: loadable(() => (
                            import(/* webpackChunkName: "sysTimer" */'./html/mgr_timer/sysTimer')
                        ))
                    }
                ]
            },
        ]
    }
];

const toMap = r => {
    const map = new Map();
    const stack = [];

    r.forEach(n => {
        map.set(n.path, n);
        stack.push(n);
    });

    let route = null;
    while ((route = stack.pop())) {
        if (route.routes && route.routes.length > 0) {
            route.routes.forEach(n => {
                map.set(n.path, n);
                stack.push(n);
            });
        }
    }

    return map;
};

export const routeMap = toMap(routes);
export default routes;
