import React, {cloneElement} from 'react';
import {connect} from 'react-redux';
import {dispatchToProps, speed, stateToProps} from '../../../utils/tools';
import {Transition} from 'react-spring/renderprops';
import {Button} from 'antd';
import {setTreeShow} from '../../actions';
import {BackwardOutlined, ForwardOutlined} from "@ant-design/icons/es/icons";
import "../../view.css";
import item from "../../../html/layout/views/item";

const FROM = {width: 200, opacity: 1};
const TO = {width: 0, opacity: 0};

const ActionBar = (c, group) => cloneElement(c, {group, buttonGroup: cloneElement(c.props.buttonGroup, {group})});
const addGroup = (c, group) => cloneElement(c, {group});

const map = {
    SearchTree: addGroup,
    "Connect(SearchTree)" : {name: "SearchTree", f: addGroup},
    ActionBar,
    "Connect(ActionBar)" : {name: "ActionBar", f: ActionBar},
    AdvancedSearchForm: addGroup,
    "Connect(AdvancedSearchForm)" : {name: "AdvancedSearchForm", f: addGroup},
};

const DefaultTemplate = ({children, table, group, isTreeShow, treeSwitchClick, optionHeight, noOption}) => {

    children = Array.isArray(children) ? children : [children];

    const obj = {defaultComp: []};
    let i = 0;
    let comp;
	
	{/*console.log(optionHeight,noOption)*/}
	let TAStyle = null;
	if(noOption){
		// 真正没有高级搜索的页面（例：模块日志）
		TAStyle = {
			'height': '100%',
			'paddingTop': '16px'
		}
	}else if(optionHeight === undefined){
		// 有高级搜索框，但不具备收缩功能（例：链接管理）
		TAStyle = {
			'height': 'calc(100% - 62px)'
		}
	}else{
		// 有高级搜索框，且具备收缩功能（例：生产信息登记）
		TAStyle = {
			'height': 'calc(100% - ' + optionHeight + 'px)'
		}
	}

    children.forEach(c => {
        if (!c) {
            return;
        }
        if ((comp = map[c.type.displayName])) {
            obj[comp.name] = comp.f(c, group);
        } else if (comp = map[c.type.name]) {
            obj[c.type.name] = comp(c, group);
        } else {
            obj.defaultComp.push(cloneElement(c, {group, key: group + (i++)}))
        }
    });

    return <div className='common-block' style={!table ? {overflow: 'auto'} : {}}>
        {/* 树控件的位置 */}
        {obj.SearchTree ? <div id="tree-block" className="tree-block">
            <Transition items={isTreeShow} enter={FROM} leave={TO} config={speed}>
                {
                    isTreeShow => {
                        return !isTreeShow && (props => {
                            return <div className="tree-content" style={{width: `${props.width}px`, opacity: `${props.opacity}`}}>
                                {obj.SearchTree}
                            </div>
                        })
                    }
                }
            </Transition>
            <div className="tree-switch-block">
                <Button className="tree-switch btn-without-style" shape="circle"
                        icon={isTreeShow ? <BackwardOutlined/> : <ForwardOutlined/>} onClick={treeSwitchClick}/>
            </div>
        </div> : null}
        {/* 表格的位置 */}
        <div id="table-block" className="table-block">
            {obj.ActionBar}
            <div style={{
                'width': 'calc(100% - 48px)',
                'height': 'calc(100% - 48px - 24px)',
                'margin': '24px',
                'marginTop': '0',
                'borderRadius': '4px',
                'backgroundColor': 'white'
            }}>
                {obj.AdvancedSearchForm}
                <div className="tableArea" style={TAStyle}>
                    {obj.defaultComp}
                </div>
            </div>
        </div>
    </div>
};

const mapStateToProps = stateToProps(({state, glState}) => ({
    isTreeShow: state && state['isTreeShow'],
    optionHeight: state['optionHeight']
}));

const mapDispatchToProps = dispatchToProps(({dispatch, ownProps}) => ({
    treeSwitchClick: () => {
        dispatch(setTreeShow());
    }
}));

export default connect(mapStateToProps, mapDispatchToProps)(DefaultTemplate);