import { get} from '../../../middlewares/request';
import messages from "../../../messages";
import {failure, data, open, search, setOtherField} from "../../../utils/actions";
import {getDict} from "../../../component/dictSelect/actions";
import {select} from '../../../utils/actions';
import {MODEL} from "../../../utils/actionNames";
import {currentTime} from "../../../utils/timeTools";
import {message} from "antd";
import {downloadExl} from "../../../utils/excel";

export const query = (name, pagination = {}) => {
	return function* (dispatch) {
		const url = messages['urls']['supervision'] + '/hntRecordTask/all';
		let generator = yield;

		yield get({
			url,
			success: resp => {
				return data({...resp.data, pagination});
			},
			fail: error => failure(error),
			dispatch,
			generator,
			data: {pagination},
			load: true,
			name,
		});
	};
};

export const getOne = (name, hntContractId) => {
	return function* (dispatch) {
		const url = messages['urls']['supervision'] + '/hntRecordTask/one';
		let generator = yield;

		yield get({
			url,
			success: resp => (
				open(name,name === MODEL ? {title: "修改"} : {title: "详情"}, resp.data)
			),
			fail: error => failure(error),
			dispatch,
			generator,
			data: {contractId: hntContractId},
			load: true,
			name,
		});

	};
};

export const print = (name, recordId) => {
	return function* (dispatch) {
		const url = messages['urls']['supervision'] + '/hntRecordTask/print';
		let generator = yield;

		yield get({
			url,
			success: resp => {
				convertData(resp.data);
				return open(name, {title: "二维码信息"}, resp.data);
			},
			fail: error => failure(error),
			dispatch,
			generator,
			name,
			data: {recordId},
			load: true
		});

	};
};


export const exportExcel = (name) => {
	return function* (dispatch) {
		const url = messages['urls']['supervision'] + '/hntRecordTask/export';
		let generator = yield;

		yield get({
			url,
			success: resp =>{
				if(!resp || !resp.data || resp.data.length == 0){
					message.warning('无数据导出！');
					return;
				}
				let data = resp.data;
				downloadExl(data, "生产信息登记");
			},
			fail: error => failure(error),
			dispatch,
			generator,
			data: {},
			load: true,
			name
		});
	};
};


const convertData = (data) => {
	data.printTime = currentTime();
	return data;
};

export const initOptArea = (defaultheight, minheight) => ({
	type: 'initOptArea',
	dheight: defaultheight,
	mheight: minheight
});

export const optionToggle = () => ({
	type: 'optionToggle'
});


export {select, search, setOtherField, getDict};
