import {get} from '../../middlewares/request';
import {failure, setData, setTreeData, treeInfo} from "../../utils/actions";

export const init = (url, name, param = {}) => {
    return function* (dispatch) {

        let generator = yield;

        yield get({
            url,
            success: resp => setTreeData(name, resp.data),
            fail: error => failure(error),
            dispatch,
            generator,
            name,
            data: {...param, pId: "1"}
        });

    };
};

export const load = (url, name, param = {}, pId, {children, resolve, treeData, loadedKeys}) => {
    return function* (dispatch) {

        let generator = yield;

        yield get({
            url,
            success: resp => {
                resp.data.forEach(item => {
                    children.push(item);
                });
                resolve();

                if(loadedKeys){
                    loadedKeys.push(pId);
                    return treeInfo(name, {
                        loadedKeys: [...loadedKeys]
                    }, [...treeData]);
                }

                return setData([...treeData], name);
            },
            fail: error => failure(error),
            dispatch,
            generator,
            data: {...param, pId}
        });

    };
};

export const search = (value, url, name, param = {}) => {
    return function* (dispatch) {

        let generator = yield;

        yield get({
            url,
            success: resp => setTreeData(name, resp.data),
            fail: error => failure(error),
            dispatch,
            generator,
            name,
            data: {...param, value}
        });
    };
};

export const getTreeData = (treeData, keys) => {

    const treeMap = {};
    const newTreeData = [...treeData];

    let l = newTreeData.length;

    for(let i = 0; i < l; i++){
        const {children, ...data} = newTreeData[i];

        if(children){
            children.forEach(item => {
                newTreeData.push(item);
                l++;
            });
        }
        treeMap[newTreeData[i].key] = data;
    }

    return keys.map(item => treeMap[item]);
}
