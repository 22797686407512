import React from 'react';
import {connect} from 'react-redux';
import {stateToProps} from '../../utils/tools';
import {Link} from 'react-router-dom';

const LinkExt = ({name, to, className, children}) => (
	<Link name={name} to={to} className={className}>{children}</Link>
);

const mapStateToProps = stateToProps(({ownProps, group}) => ({
    name: ownProps.name + group
}));

export default connect(mapStateToProps)(LinkExt);