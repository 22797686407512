import loadable from "@loadable/component";
import resetStore from '../enhancer/resetStore';
import {
	ADD_FORM_INFO,
	CHANGE_DELETE_FILE,
	CHANGE_UPLOAD_FILE,
	CHANGEFORM,
	CHANGEOTHERFORM,
	DATA,
	DELETE_STATE,
	FAILURE,
	FORM,
	INIT_OPT_AREA,
	LOADING,
	NEED_INIT,
	OPTION_TOGGLE,
	OTHER_VALUE,
	SEARCH,
	SELECT,
	SET_TREE_SHOW,
	SETDICT,
	SETOTHERFIELD,
	TOGGLEMODAL,
	TREE_INFO
} from './actionTypes';
import {createNewTab} from '../actions';
import store from '../store';
import sysConf from './sysConfig';

let nextSeqId = 0;

const {speed, global, stateKey, empty} = sysConf;

const genIdx = () => (++ nextSeqId);
const load = (promise) => loadable(() => {
	return resetStore(promise);
});


const createNewPage = (dispatch, id, title, url, params) => {
	dispatch(createNewTab(id, title, url, params));
};

const removeClass = (ele, txt) => {
    ele.className = ele.className && ele.className.indexOf(txt) > -1 ? ele.className.replace(txt, "") : ele.className;
};

const addClass = (ele, txt) => {
	ele.className += txt;
};

const populateButton = (d, idModuleCommand) => {
	let second = null;
	if(d instanceof NodeList) {
		d.forEach((n) => {
			removeClass(n, "none");

			second = n.children && n.children[1];

			if(second && second.tagName == 'SPAN') {
				second.innerText = idModuleCommand['mcName'];
			} else {
				n.title = idModuleCommand['mcName'];
			}
		});
	} else {
		removeClass(d, "none");

		second = d.children && d.children[1];

		if(second && second.tagName == 'SPAN') {
			second.innerText = idModuleCommand['mcName'];
		}
	}
};

const populatePermission = (group) => {

	const state = store.getState();
	const staff = state[global]['staff'];
	const url = state[global][stateKey];

	if(!staff || !staff.urlModuleCommands || !url || !staff.urlModuleCommands[url]) {
		return;
	}

	const idModuleCommands = staff.urlModuleCommands[url];
	let d = null;

	let mcEltId = null;

	if(idModuleCommands) {
		idModuleCommands.forEach(idModuleCommand => {

			mcEltId = idModuleCommand['mcEltId'];
			if(idModuleCommand['mcEltId']) {

				const id = mcEltId + group;
				d = document.getElementById(id);

				if(d) {
					populateButton(d, idModuleCommand);
				} else {
					d = document.getElementsByName(id);

					if(d.length > 0) {
						populateButton(d, idModuleCommand);
					}
				}
			}
		});
	}

};

const extRender = fn => {

	return (text, record) => {
		const state = store.getState();
		const url = state['router']['location']['pathname'];
		const commands = state[global]['staff']['urlModuleCommands'][url];
		const perm = new Set();
		if(commands) {
			commands.forEach((item) => {
				perm.add(item['mcEltId']);
			});
		}
		//perm.has("refresh");
		return fn(text, record, perm);
	}
};

const btnPromise = id => {
	const state = store.getState();
	const url = state['router']['location']['pathname'];
	const commands = state[global]['staff']['urlModuleCommands'][url];

	if(!commands || !(id in commands)){
		return {display:'none'}
	}
}

const stateToProps = fn => {
	return (state, ownProps) => {
		const glState = state[global];
		let group = null, st = null;
		if(ownProps.group) {
			group = ownProps.group;
			st = state[ownProps.group] ? state[ownProps.group] : glState[ownProps.group];
		} else {
			group = glState[stateKey];
			st = state[glState[stateKey]];
		}
		return fn({state: st, ownProps, glState, group});
	}
};

const dispatchToProps = fn => {
	return (dispatch, ownProps) => {
		const sk = ownProps.group ? ownProps.group : store.getState()[global][stateKey];
		return fn({dispatch, ownProps, store, global, sk});
	}
};

const toggle = (state, name, flag, dataName, modelData, data) => {

	const c = flag ?
		{...state, modelData: {...(state.modelData || {}), [name]: modelData}, [dataName]: data || state[dataName] }:
		{...state};

	c['visible'] = c['visible'] ? c['visible'] : {};
	c['visible'] = {...c['visible'], [name]: flag};
	return c;
};

const populateAndConvertTree = (data, isSelect) => {

	const id = isSelect ? "id" : "key";

	if (data.length <= 1) {
		return {treeData: data, info: {expandedKeys: [], checkedKeys: [], loadedKeys: []}}
	}

	const map = new Map();
	let p = null;
	const treeData = [];
	const expandedKeys = [];
	const checkedKeys = [];
	const rootPId = data[0].pId;
	data.forEach(item => {
		item.expanded && expandedKeys.push(item[id]);
		item.checked && checkedKeys.push(item[id]);
		map.set(item[id], item);
	});

	!isSelect && data.forEach(item => {
		if (item.pId && item.pId !== rootPId) {
			p = map.get(item.pId);
			if (!p.children) {
				p.children = [];
			}
			p.children.push(item);
		}
	});

	treeData.push(data[0]);


	return {treeData: isSelect ? data : treeData, info: {expandedKeys, checkedKeys, loadedKeys: expandedKeys}};
};

const treeInfo = (state, name, info, data, isSelect) => {

	const {treeInfo: treeInfo_ = {}} = state;

	let newInfo = {...(treeInfo_[name] || {}), ...(info || {})};

	if(!info && data){
		const {treeData, info} = populateAndConvertTree(data, isSelect);
		data = treeData;
		newInfo = {...newInfo, ...info};
	}

	return {
		...state,
		treeInfo: {...treeInfo_, [name]: newInfo},
		[name]: data || state[name]
	};
};

const needInit = (state, name, isInit) => {
	const needInit_ = state.needInit || {};
	return {...state, needInit: {...needInit_, [name]: isInit ? isInit : !needInit_[name]}};
};

//通用的以name切分
const setNameState = (state, name, val, key) => (
	{...state, [key]: {...(state[key] || {}), [name]: val}}
);


const addNameState = (state, name, val, key) => {
	const keyValue =  state[key] || {};
	return {...state, [key]: {...keyValue, [name]: {...(keyValue[name]|| {}), ...val}}}
};

const deleteState = (state, names, group) => {

	if(group && (state[stateKey] !== group)) {
		return {...state};
	}

	let l;
	names.forEach(arr => {
		let delProps = state;
		if(arr instanceof Array){
			l = arr.length - 1;
			for (let i = 0; i < l; i++){
				delProps = delProps && delProps[arr[i]];
			}
			delProps && delProps[arr[l]] && delete delProps[arr[l]];
		} else {
			delProps[arr] && delete delProps[arr];
		}
	});
	return {...state};
};

const changeDeleteFile = (state, name, path, file) => {

	const fileInfo_ =  state.fileInfo || {};
	const thisFileInfo = fileInfo_[name] || {};

	let {deleteFiles = [], ...otherProps} = thisFileInfo;

	return {...state, fileInfo: {...fileInfo_, [name]: {path, ...otherProps, deleteFiles: [...deleteFiles, file]}}};
};

const changeUploadFile = (state, name, path, file, isAdd) => {

	const fileInfo_ =  state.fileInfo || {};
	const thisFileInfo = fileInfo_[name] || {};

	let {uploadFiles = {}, ...otherProps} = thisFileInfo;


	if(isAdd){
		uploadFiles = {...uploadFiles, [file.uid]: file};
	} else {
		const {[file.uid]: d, ...newUploadFiles} = uploadFiles;
		uploadFiles = newUploadFiles;
	}

	return {...state, fileInfo: {...fileInfo_, [name]: {path, ...otherProps, uploadFiles}}};
};

const extendReducer = (reducer) => {

	return (state, action) => {

		switch(action.type) {
			case TOGGLEMODAL:
				return toggle(state, action.val, action.flag, action.dataName, action.modelData, action.data);

			case CHANGEFORM:
				return {...state, fields: {...state['fields'], ...action.val}};

			case CHANGEOTHERFORM:
				return {...state, otherFields: {...state['fields'], ...action.val}};

			case LOADING:
				return action.name ? {...state, loading: {...state.loading, [action.name]: action.val}, err: action.val ? '' : state['err']}  : {...state, err: action.val ? '' : state['err']};

			case DATA:
				return {...state, [action.name]: action.val};

			case FAILURE: return {...state, err: action.val};

			case SELECT: return setNameState(state, action.name, action.ids, "ids");

			case NEED_INIT: return needInit(state, action.name, action.isInit);

			case TREE_INFO:
				return treeInfo(state, action.name, action.treeInfo, action.data, action.isSelect);

			case SEARCH: return {...state, [action.name]: {filter: action.search}};

			case SETOTHERFIELD:
				return {...addNameState(state, "otherFields", action.val , "resetValues"), otherFields: action.val};

			case OTHER_VALUE:
				return addNameState(state, action.name, action.value , "otherValue");

			case FORM:
				return setNameState(setNameState(state, action.name, action.value , FORM), action.name, action.resetValues , "resetValues");

            case SETDICT: return {...state, setDict: [...(state.setDict || []), ...action.setDict]};

			case DELETE_STATE: return deleteState(state, action.keys, action.group);

			case SET_TREE_SHOW: return {...state, isTreeShow: !state['isTreeShow']};

			case OPTION_TOGGLE:
				return state.optionShow ? {...state, optionHeight: state.minHeight, optionShow : false} : {...state, optionHeight: state.defaultHeight, optionShow : true};

			case INIT_OPT_AREA:
				return {...state, optionShow: false, optionHeight: action.mheight, defaultHeight: action.dheight, minHeight: action.mheight};

			case CHANGE_DELETE_FILE:
				return changeDeleteFile(state, action.name, action.path, action.file);

			case CHANGE_UPLOAD_FILE:
				return changeUploadFile(state, action.name, action.path,  action.file, action.isAdd);

			case ADD_FORM_INFO:
				return addNameState(state, action.name, action.info , "formInfo");
				
			case 'customTipShow':
				return {...state, 'custipshow': true, 'custiptxt': action.info.text, 'custiptype': action.info.type};
				
			case 'customTipHide':
				return {...state, 'custipshow': false, 'custiptxt': '', 'custiptype': null};

			default: return reducer(state, action);
		}
	}

};

export {genIdx, load, empty, speed, stateToProps, dispatchToProps, extendReducer, createNewPage, btnPromise, populatePermission};
