import XLSX from 'xlsx';

const arr = new Set(['企业名称', '施工单位', '工程名称', '质量监督站', '工程地址', '警示信息', "时间"]);

export const curentTime = () => {
	var now = new Date();
	var year = now.getFullYear(); //年
	var month = now.getMonth() + 1; //月
	var day = now.getDate(); //日

	var hh = now.getHours(); //时
	var mm = now.getMinutes(); //分
	var ss = now.getSeconds(); //秒
	return `${year}${month}${day}${hh}${mm}${ss}`;
}

const downloadExl = (data, name) => {
	name = name + curentTime();
    const wb = {SheetNames: [name], Sheets: {}, Props: {}};
    const wopts = {bookType: 'xlsx', bookSST: false, type: 'binary'};
    wb.Sheets[name] = XLSX.utils.aoa_to_sheet(data);//通过json_to_sheet转成单页(Sheet)数据
    var wscols = [];
    
    for(var i = 0; i < data[0].length; i++) {
    	wscols.push({wpx: arr.has(data[0][i]) ? 200 : 120});
    }
    
    wb.Sheets[name]['!cols'] = wscols;
    XLSX.writeFile(wb, name + '.xlsx');
};

export const downloadDetailEXl = (data, name, lines) => {
	name = name + curentTime();
	const wb = {SheetNames: [name], Sheets: {}, Props: {}};
	const wopts = {bookType: 'xlsx', bookSST: true, type: 'binary'};
	wb.Sheets[name] = XLSX.utils.aoa_to_sheet(data);//通过json_to_sheet转成单页(Sheet)数据
	wb.Sheets[name]["!merges"] = lines;
	const wscols = [];
	wb.Sheets[name]['!cols'] = wscols;
	XLSX.writeFile(wb, name + '.xlsx');
}

export {downloadExl};