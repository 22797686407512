import React from 'react';
import {Tree} from 'antd';
import {dispatchToProps, stateToProps} from "../tools";
import {connect} from "react-redux";
import {deleteMore, treeInfo} from "../actions";
import {getTreeData, init, load} from "./actions";
import TreeComponent from "./searchTree";
import FormTreeSelect from "./treeSelect";
import TreeField from "./treeField";
import {TREE_INFO} from "../actionTypes";
import base from "../base";

const {DirectoryTree} = Tree;

const BasicTree = base(({onLoadData, name, expandedKeys, group, active, ...otherProps}) => (
    <DirectoryTree
        expandedKeys={expandedKeys}
        expandAction={false}
        {...otherProps}
    />
));

const mapStateToProps = stateToProps(({ownProps: {name}, state: {[name]: treeData = [], treeInfo}}) => ({
    treeData,
    expandedKeys: treeInfo && treeInfo[name] && treeInfo[name].expandedKeys,
    loadedKeys: treeInfo && treeInfo[name] && treeInfo[name].loadedKeys,
}));

const mapDispatchToProps = dispatchToProps(({dispatch, ownProps: {name, initUrl, loadUrl, param = {}, initParam = {}, loadParam = {}, notInit}, sk, store}) => ({

    onInit: () => {
        !notInit && dispatch(init(initUrl || loadUrl, name, {...param, ...initParam}));
    },

    loadData: (treeNode) => {

        const {key, children} = treeNode;

        return new Promise(resolve => {
            if (treeNode.isLeaf || (children && children.length > 0)) {
                resolve();
                return;
            }

            const {[name]: treeData = [], treeInfo} = store.getState()[sk];

            dispatch(load(
                loadUrl instanceof Function ? loadUrl(treeNode) : loadUrl,
                name,
                {...param, ...(loadParam && loadParam instanceof Function ? loadParam(treeNode) : loadParam)},
                key,
                {children, resolve, treeData, loadedKeys: treeInfo && treeInfo[name] && treeInfo[name].loadedKeys,}
            ));
        });
    },

    onExpand: expandedKeys => {
        dispatch(treeInfo(name, {expandedKeys}));
    },

    onRemove: () => {
        dispatch(deleteMore([name, [TREE_INFO, name]]));
    }

}));

export default connect(mapStateToProps, mapDispatchToProps)(BasicTree);
export {TreeComponent, TreeField, FormTreeSelect, getTreeData}