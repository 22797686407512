import React, {useState} from 'react';
import {connect} from 'react-redux';
import {dispatchToProps, stateToProps} from '../../utils/tools';
import {actionBar, changeParams} from '../../actions';
import {Button, Input} from 'antd';
import {animated, useSpring} from 'react-spring';
import {DownOutlined, UpOutlined} from '@ant-design/icons';

import './actionBar.css';


const ActionBar = (props) => {
    const {buttonGroup, simpleSearch, moreSearchForm, changeActionBar, actionBarState, onClick, children} = props;

    const actBarMore = document.getElementById("actionBarMoreAct");

    let state = typeof actionBarState == 'undefined' ? false : actionBarState;

    const [open,setOpen] = useState(state);

    const aniProps = useSpring({
        from:{height: 0,opacity: 0},
        to:{height: open && actBarMore ? actBarMore.childNodes[0].clientHeight : 0,opacity: open ? 1 : 0},
        onRest:(e)=>{
            e.opacity == 0 ? changeActionBar(false) : changeActionBar(true);
        }
    });

    const simpleSearchProps = useSpring({
        from:{opacity: 0},
        to:{opacity: open ? 1 : 0},
    })

    const handle = function(){

        setOpen(!open);

    }

    const moreOpen = {
        'backgroundColor':'#fd7e14',
        'color':'#fff'
    }

    const moreClose = {
        'backgroundColor':'#fff',
        'color':'rgba(0, 0, 0, 0.65)'
    }

    return <div className="actionBar" id="actionBar">
        <div className="actionBarSimpleAct">
            <div className="actionBarButtonGroup">
                {buttonGroup}
            </div>
            <div className="actionBarSimpleSearch" style={simpleSearchProps}>
                {
                    !open ? simpleSearch : null
                }
                {moreSearchForm ? <Button icon={!open?<DownOutlined />:<UpOutlined />} className="btn-shape btn-more" style={!open?moreOpen:moreClose} onClick={handle}>{!open?'更多':'收起'}</Button> : null}
            </div>
        </div>
        <animated.div className="actionBarMoreAct" style={aniProps} id="actionBarMoreAct">
            {moreSearchForm}
        </animated.div>
    </div>
};



const mapStateToProps = stateToProps(({state, glState}) => ({
    actionBarState: glState['actionBar']
}));

const mapDispatchToProps = dispatchToProps(({dispatch, ownProps}) => ({
    changeActionBar:(obj) => {
        dispatch(actionBar(obj));
    },
    changeParams:(p) => {
        dispatch(changeParams(p));
    }
}));

export default connect(mapStateToProps, mapDispatchToProps)(ActionBar);