import store from '../store';
import {combineReducers} from 'redux';
import sysConfig from '../utils/sysConfig';

const {global, stateKey} = sysConfig;

export const storeHistory = () => {
	
	const state = store.getState();
	
	if(!state) {
		return;
	}
	
	for(let key in state) {
		if(!store._reducers.hasOwnProperty(key)) {
			state[global][key] = state[key];
		}
	}
};

export const resetStore = (reducer, p, sk) => {
	
	const state = store.getState();
	
	if(!state) {
		return p;
	}
	
	if(!reducer) {
		return p;
	}
	
	if(!sk && (!state['router'] || !state['router']['location'] || !state['router']['location']['pathname'])) {
		return p;
	}
	
	const stk = sk ? sk : state['router']['location']['pathname'];
	state[global][stateKey] = stk;
	state[global]['reset'] = null;
	
	store.reset(combineReducers({
	  ...store._reducers,
	  [stk]: reducer
	}), {
	  ...state,
	  [stk]: state[global][stk] || {}
	});
	
	return p;
}

export default (p) => {
  return p.then(function({reducer}) {
	storeHistory();
	return resetStore(reducer, p);
  }).catch(function(err) {
	return p;
  });
};