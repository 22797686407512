import {
	TOKEN,
	STAFF,
	CLEAR,
	SELMENU,
	SELSUBMENU,
	SEL,
	TOGGLE,
	TOGGLETAB,
	CLSUBMENU,
	SELTAB,
	CLALLTAB,
	CLOTHERTAB,
	CLOSETABBUT,
	MOVE,
	WINDOWRESIZE,
	ACTIONBAR,
	PARAMS,
	NEWTAB,
	SHOWMORE,
	SELECTMORE,
	RESTORE_TAB,
	SET_OSS_DATA
} from './actionTypes';

const exist = (b, subItem, item, mid) => {
	let temp = null;
	subItem.selected = true;
	if(!b['tab']) {
		temp = [];
		subItem.path = [mid, item.moId, subItem.moId];
		temp.push(subItem);
	} else if(!b['tab'].some(t => t.moId === subItem.moId)) {
		temp = b['tab'];
		subItem.path = [mid, item.moId, subItem.moId];
		temp.push(subItem);
	} else {
		temp = b['tab'];
	}

	b['tabAct'] = subItem;
	//b['same'] = temp === b['tab'] ? true : false;
	b['tab'] = temp;
};

export default (state={}, action) => {

	switch(action.type) {
		case TOKEN: 
			return {...state, token: action.val};
		
		case STAFF: return {...state, staff: action.val, token: action.token};
		
		case CLEAR: return {...state, staff: action.val, token: null};
		case MOVE: return {...state, margin: state['margin'] !== undefined ? (state['margin'] + action.val) : action.val, upd: false};
		case SELTAB: 
			let ta = state.tabAct;
			state['tab'].some((item) => {
				if(item['moId'] === action.val) {
					ta = item;
					return true;
				}
			});
			
			const t = state['staff']['menu'];
			
			let id = null;
			if(ta && ta['path']) {
				id = ta['path'][0];
				const smid = ta['path'][1];
				t.set(id, t.get(id).map((item) => {
					item['open'] = item['moId'] === smid ? true : false;
					return item;
				}));
			}
			
			state['reset'] = state['tabAct']['moId'] != ta.moId ? ta.moPage : null;
			
			return {...state, id: id, tabAct: ta, upd: true, force: false};
		case CLSUBMENU: 
			const tab = state['tab'].filter(item => item.moId !== action.val);
			const last = tab.length - 1;
			let tabAct = state.tabAct;
			let tid = state.id;
			if(tabAct && action.val === tabAct['moId'] && tab.length > 0) {
				//tab[last]['open'] = true;
				tabAct = tab[last];
				state['reset'] = tabAct.moPage;
			} else {
				if(tab.length <= 0) {
					tabAct = null;
					state['staff']['menu'].get(state['id']).some(it => {
						if(it['open']) {
							it['open'] = false;
							return true;
						}
					});
					tid = null;
				}
			}
			return {...state, tab: tab, tabAct: tabAct, upd: true, id: tid};
		case SEL: return {...state, id: action.val, moreactive: false, moreshow: false};
		case SELSUBMENU: 
			const b = {...state, upd: true, id: state['id'] ? state['id'] : state['staff']['idModules'][0]['idModules'][0]['moId']};
			const bm = b['staff']['menu'];
			const bma = bm.get(b['id']);
			bma && bma.every((item) => {
				if(item['open']) {
					item['idModules'].forEach((t) => {
						if(t['moId'] === action.val) {

							b['reset'] = !b['tabAct']
											? t.moPage
											: b['tabAct']['moId'] != t.moId
											? t.moPage
											: null;

							exist(b, t, item, b['id']);
						}
					});
					return false;
				} else {
					return true;
				}
			});
			return b;
		case SELMENU: 
			const result = {...state, id: state['id'] ? state['id'] : state['staff']['idModules'][0]['idModules'][0]['moId'], force: false};
			const map = result['staff']['menu'];
			map.set(result['id'], map.get(result['id']).map(item => {
				item['open'] = item['moId'] !== action.val ? false : action.state === undefined ? !item['open'] : action.state;
				return item;
			}));
			
			return result;
		case TOGGLE: 
			const r = {...state, hide: !state.hide, id: state['id'] ? state['id'] : state['staff']['idModules'][0]['idModules'][0]['moId']};
			const m = r['staff']['menu'];
			let force = false;
			m.set(r['id'], m.get(r['id']).map((item) => {
				if(item['open']) {
					force = true;
				}
				
				item['open'] = false;
				return item;
			}));
			
			r['force'] = force;
			return r;
			
		case TOGGLETAB: 
			return {...state, tabBut: !state['tabBut']};
			
		case CLALLTAB: 
		
			let me = null;
			if(state["id"] && (me = state['staff']['menu'])) {
				for(let [,v] of me) {
					v.forEach(item => {
						item['open'] = false;
					})
				}
			}
			
			return {...state, tab: [], tabAct: null, margin: undefined, tabBut: !state['tabBut']};
		case CLOTHERTAB: 
			return {...state, margin: undefined, tab: state['tab'] && state['tab'].filter(item => state['tabAct'] && item.moId === state['tabAct']['moId']), tabBut: !state['tabBut']};
			
		case CLOSETABBUT: 
			return {...state, tabBut: false};
			
		case WINDOWRESIZE:
			return {...state, windowResize:action.windowResize};
		
		case ACTIONBAR:
			return {...state, actionBar:action.actionBar};
		
		case PARAMS:
			return {...state, params:action.params};
			
		case NEWTAB:
			const nb = {...state, upd: true};
			const stkId = action.url + "?" + action.id;
			const nt = {moId: stkId, moLevel: 3, moName: action.title, moPage: stkId, moType: 0, selected: false, params: action.params};

			nb['reset'] = null;
				
			if(state['tabAct']['moId'] != stkId) {
				nb['reset'] = stkId;
			}
			
			nb['tabAct'] = nt;
			const ntb = nb['tab'] ? [...nb['tab']] : [];
			const isExist = ntb.findIndex((obj) => {
				return obj.moId === nt.moId;
			});
			isExist === -1 && ntb.push(nt);
			nb['tab'] = ntb;

			return nb;

		case RESTORE_TAB:

			const tbt = state['staff']['menu'];
			for(let [k, v] of tbt) {
				v && v.some((item) => {
					return item['idModules'].some((t) => {
						if(t['moPage'] === action.url) {
							exist(state, t, item, k);
							item['open'] = true;
							state.id = k;
							return true;
						}
					});
				});
			}

			return {...state};

		case SHOWMORE:
			const newState = state['morelist'] === undefined ? {...state, morelist: [], moreshow: false, left: 0} : {...state};
			newState['morelist'] = action.list;
			newState['moreshow'] === true ? newState['moreshow'] = false : newState['moreshow'] = true;
			newState['left'] = action.left;
			return newState;

		case SELECTMORE:
			const newState2 = {...state, moreactive: true};
			newState2['moreshow'] = false;
			newState2['id'] = action.id;
			return newState2;

		case SET_OSS_DATA:
			return {...state, ossData: {...(state.ossData || {}), [action.path] : action.data}};
		default: return state;
	}
};