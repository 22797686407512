import React from 'react';
import {connect} from 'react-redux';
import {dispatchToProps, stateToProps} from '../../utils/tools';
import {Button} from 'antd';
import {EXPORT_BTN, QUERY, REFRESH_BTN} from "../actionNames";
import {messageMethod} from "../message";

const ButtonExt = ({id, icon, onClick, className, children}) => (
    <Button id={id} icon={icon} onClick={onClick} className={className}>{children}</Button>
);

const mapStateToProps = stateToProps(({ownProps, group}) => ({
    id: ownProps.id + group
}));

const mapDispatchToProps = dispatchToProps(({ownProps: {onClick, type, tableName = QUERY, maxSize = 1000}, sk, store}) => ({

    onClick: type === REFRESH_BTN ? () => {

        const state = store.getState()[sk];
        let pagination = state[tableName] && state[tableName].pagination;
        pagination = pagination && {pageSize: pagination.pageSize};
        onClick && onClick(pagination, tableName);

    } : type === EXPORT_BTN ? () => {

        const state = store.getState()[sk];
        let recordsTotal = state[tableName] && state[tableName].recordsTotal;
        if (recordsTotal > maxSize) {
            messageMethod('导出数据过大，请缩小导出数量！', "error");
            return;
        }
        onClick && onClick(tableName);

    } : onClick

}));

export default connect(mapStateToProps, mapDispatchToProps)(ButtonExt);