import base from './base';
import Table from './table';
import ExtTableSelect from './tableSelect';
import PageComponent from './page';
import ActionBar from './actionBar';
import {downloadExl} from './excel/';
import Modal from './modal';
import LineChart from './chartJs';
import Content from './content';
import FormBlock from './formBlock';
import Tree, {TreeComponent, TreeField, FormTreeSelect, getTreeData} from './tree';
// import Tree, {TreeComponent, TreeField, getTreeData} from './tree';
import SearchForm from './form/searchForm';
import AddressSelect from './addressSelect';
import Label from './label';
import Spin from './spin';
// import FormTreeSelect from './treeSelect';
import ExtForm from './form';
import ExtQRCode from "./qrCode";
import DynamicFormItem from "./dynamicFormItem";
import {ModalForm, PageForm} from './form';
import FormTable from "./formTabel";
import ReactQuillComponent from './reactQuill';
import * as Rule from './form/rules';
import * as Oss from './ossClient';
import OssUpload from "./ossClient";
import Button from './button';
import Link from './link';
import A from './a';
import InputBtn from './inputBtn';
import MoreDropdown from './dropDown'

export {
	base,
    Table,
    Modal,
    ModalForm,
    SearchForm,
    PageComponent,
    ExtTableSelect,
    ActionBar,
    downloadExl,
    FormBlock,
    TreeComponent,
    TreeField,
    Content,
    LineChart,
    AddressSelect,
    Label,
    Spin,
    FormTreeSelect,
    Tree,
    ExtQRCode,
    ExtForm,
    DynamicFormItem,
    PageForm,
    FormTable,
    ReactQuillComponent,
    Rule,
    OssUpload,
    getTreeData,
    Oss,
	Button,
    MoreDropdown,
	Link,
	A,
    InputBtn
};
