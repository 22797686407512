import React, {forwardRef} from "react";
import {connect} from "react-redux";
import {Dropdown, Input, Menu} from "antd";
import {dispatchToProps, stateToProps} from "../../tools";
import {close, deleteOne, open, treeInfo} from "../../actions";
import {NEED_INIT} from "../../actionTypes";
import base from "../../base"
import TreeField from "../treeField";
import {search} from "../actions";

const {Search} = Input;

const TreeSelect = base(({placeholder, disabled, onVisibleChange, onSearch, onRemove, visible, label, onFocus, focus, searchLabel, searchOnChange, ...otherProps}, ref) => {

    const searchProps = visible ? {value: searchLabel, onSearch, onChange: searchOnChange, onFocus, placeholder :label||placeholder} : {onFocus, value: label, placeholder};

    return (
        <Dropdown overlay={<Menu><TreeField height={300} disabled={disabled} checkable={false} {...otherProps}/></Menu>}
                  trigger={['click']} onVisibleChange={onVisibleChange} placement="bottomCenter" visible={visible}>
            <Search {...searchProps} allowClear/>
        </Dropdown>
    )
});

const mapStateToProps = stateToProps(({state: {visible, treeInfo, modelData}, ownProps: {name}}) => ({
    visible: visible && visible[name],
    label: treeInfo && treeInfo[name] && treeInfo[name].label,
    searchLabel: treeInfo && treeInfo[name] && treeInfo[name].searchLabel,
}));

const mapDispatchToProps = dispatchToProps(({dispatch, ownProps: {name, initUrl, loadUrl, searchUrl, param = {}, initParam = {}, searchParam = {}}, sk, store}) => ({

    onFocus: () => {
        dispatch(open(name, {focus: true}));
    },

    onVisibleChange: (v) => {
        const {modelData} = store.getState()[sk];

        !v && dispatch(treeInfo(name, {searchLabel: ""}));

        if (modelData && modelData[name] && modelData[name].focus) {
            dispatch(open(name, {focus: false}));
            return;
        }

        v ? dispatch(open(name)) : dispatch(close(name));
    },

    onRemove: () => {
        dispatch(deleteOne([NEED_INIT, name]));
    },

    onSearch: value => {
        dispatch(open(name));
        value = value.target ? value.target.value : value;
        if (value) {
            dispatch(search(value, searchUrl, name, {...param, ...searchParam}));
        } else {
            // dispatch(init(initUrl || loadUrl, name, {...param, ...initParam}));
        }
    },

    // inputOnChange: (value) => {
    //     dispatch(treeInfo(name, {label: value.target ? value.target.value : value}));
    // },

    searchOnChange: (value) => {
        dispatch(treeInfo(name, {searchLabel: value.target ? value.target.value : value}));
    }

}));


export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(forwardRef(TreeSelect));
