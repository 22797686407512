import Crypto from 'crypto';
import OSS from 'ali-oss';
import {get} from '../../middlewares/request';
import messages from '../../messages.js';
import store from "../../store";
import loadingImg from "./loading.png";
import {setOssData} from "../../actions";
import {addDeleteFiles, addUploadFiles, deleteMore, removeUploadFiles, setInit, open} from "../actions";

export const DEFAULT = "default";
export const LOGO = "logo";

const getExpiration = () => {
    const now = new Date();
    now.setMinutes(now.getMinutes() + 50);
    return now.toJSON();
};

const getPolicy = (bucket) => (new Buffer(
        JSON.stringify({
            expiration: getExpiration(),
            conditions: [{bucket}]
        })
    ).toString('base64')
);

const signature = (policyText, accesskey) => {
    const signature = Crypto.Hmac('sha1', accesskey)
        .update(policyText)
        .digest('base64');
    return signature;
};

export const getOSSToken = (resolve, folder, isFlag, reject) => {

    const url = messages['urls']['idCenter'] +
        (!folder ? '/staff/getOSSToken' :
            folder === LOGO ? '/staff/getLogoOSSToken'
                : '/staff/getFolderOSSToken');

    return function* (dispatch) {
        let generator = yield;

        yield get({
            url,
            success: resp => {
                setOss(resp.data, dispatch, resolve, folder);
            },
            fail: () => {
                reject();
                dispatch(setOssData(folder, null));
            },
            dispatch,
            data: {folder, isFlag},
            generator
        });
    };
};

const setOss = ({accessKeyId, accessKeySecret, securityToken: stsToken, bucket, folder, endpoint}, dispatch, resolve, path) => {
    const policy = getPolicy(bucket);

    const ossClient = new OSS({
        region: messages['urls']['region'],
        accessKeyId,
        accessKeySecret,
        stsToken,
        bucket,
    });

    folder = folder.charAt(folder.length - 1) !== "/" ? folder + "/" : folder;

    dispatch(setOssData(path, {
        ossClient,
        dt: {
            success_action_status: 200,
            policy,
            Signature: signature(policy, accessKeySecret),
            OSSAccessKeyId: accessKeyId,
            "x-oss-security-token": stsToken
        },
        folder,
        action: `${messages['urls']['htp']}${bucket}.${endpoint}`,
        expire: Date.now() + 50 * 60 * 1000
    }));
    if (resolve) {
        resolve({ossClient, folder});
    }
};

let id = -1;

export const getImg = (name, realName, isImg) => ({
    uid: "" + id--,
    name,
    realName: realName|| name,
    status: 'done',
    thumbUrl: isImg ? loadingImg : undefined,
    loading: true,
    isOriginal: true
});

export const getOssData = (path = DEFAULT, isFlag) => {

    const {ossData} = store.getState().global;

    let promise;

    if (!ossData || !ossData[path] || ossData[path].expire < Date.now()) {
        promise = new Promise((resolve, reject) => {
            store.dispatch(getOSSToken(resolve, path, isFlag, reject));
        });
        store.dispatch(setOssData(path, promise));
    } else {
        promise = ossData[path];
    }

    return Promise.all([promise]);
};


export {addDeleteFiles, addUploadFiles, deleteMore, removeUploadFiles, setInit, open};