import React, {useEffect, useRef, Fragment, cloneElement} from 'react';
import {connect} from 'react-redux';
import {dispatchToProps, populatePermission, stateToProps} from '../tools';
import {deleteMore, select} from '../actions';
import base from '../base';
import {Divider, Table, Tooltip} from 'antd';
import "./table.css";
import {A, MoreDropdown} from "../index";

const PAGER = {
    showSizeChanger: true,
    showQuickJumper: true,
    showLessItems: true,
    current: 1,
    pageSize: 10,
    showTotal: total => '总共' + total + '条'
};

const remove = (arr, removeArr) => {
    const set = new Set(arr);
    removeArr.forEach(item => {
        set.delete(item);
    });
    return Array.from(set);
};

const add = (arr, addArr) => {
    const set = new Set(arr);
    addArr.forEach(item => {
        set.add(item);
    });
    return Array.from(set);
};

const getRowKey = (rowKey, record) => rowKey instanceof Function ? rowKey(record) : record[rowKey];

const ExtTable = base(({
                           onSelect, onChange, dataSource, sels, rowKey, size, name, selectedRowKeys = [],
                           onRowSelectionOpt, filter = {}, type = "checkbox", tabletype='normalHeader', noSel, emptySelect, noClearSelect, onSelectAll, loading, columns, group
                       }) => {

    useEffect(() => {
        if (!noClearSelect && !noSel) {
            emptySelect();
        }
    }, [dataSource]);

    const cols = useRef(null);
    cols.current = cols.current ? cols.current : columns.map((item, i) => {
        const {width, render, title} = item;
        item.width = width;
        if (i === columns.length - 1 && title === "操作") {
            item.render = (record, index) => {
                const c = render(record, index);
                if(!c || !c.props || !c.props.children || (!c.props.children.props && !Array.isArray(c.props.children))){
                    return c;
                }
                const children = Array.isArray(c.props.children) ? [...c.props.children] : [c.props.children];
                let l = children.length * 2 - 1;
                const newChildren = [];
                if (children.length > 3) {
                    children[2] = <MoreDropdown menuList={children.slice(2, children.length)} group={group}/>;
                    l = 5;
                }
                let i = 0;
                while (true) {
                    newChildren[i] = cloneElement(children[i/2], {group, key: i});
                    if(++i >= l){
                        break;
                    }
                    newChildren[i] = <Divider type="vertical" key={i++}/>;
                }
                return cloneElement(c, {}, newChildren);
            };

            return item;
        }
        const moreWidth = `calc(${width}px - 16px)`;
        if (render) {
            {/*
				item.render = (text, record, index) => {
					return <div className="longtxt" style={{width: moreWidth}}>{render(text, record, index)}</div>
				}
			*/
            }
            item.render = (record, index) => {
                return <Fragment>{render(record, index)}</Fragment>
            }
        } else {
            item.render = (text) => {
				let t = text === undefined ? '-' : text;
				return (
					<Tooltip placement="topLeft" title={t}>
						<div className="longtxt" style={{'maxWidth': moreWidth}}>{t}</div>
					</Tooltip>
				)
			}
        }
        return item;
    });

    const selected = sels ? sels : [];

    const SCROLL = {x: 'max-content', y: size ? size - 115 : 0};

    let onRowSelection = noSel ? null : onRowSelectionOpt ? onRowSelectionOpt : {
        onSelect,
        onSelectAll,
        getCheckboxProps: record => ({
            disabled: selected.some(item => item === getRowKey(rowKey, record)),
            name: 'n' + getRowKey(rowKey, record)
        }),
        selectedRowKeys: selectedRowKeys,
        type
    };

    let total = 0;
    let dt = null;
    let pager = null;
    if (dataSource) {
        total = dataSource.recordsTotal;
        dt = dataSource.data;
        pager = dataSource.pagination && dataSource.pagination.current ? {...dataSource.pagination} : {
            ...PAGER,
            ...(dataSource.pagination || {}),
            total,
        };
    } else {
        dt = [];
        pager = {...PAGER, total};
    }
	
	// console.log(tabletype);
    return (<Table className={'fixedWidthTable '+tabletype} size="small" rowSelection={onRowSelection} pagination={pager} bordered
                   dataSource={dt} rowKey={rowKey} scroll={SCROLL} onChange={onChange(name, filter)} loading={loading}
                   columns={cols.current}/>);
});

const mapStateToProps = stateToProps(({ownProps: {name}, state: {[name]: source, ids, loading}, glState: {windowResize: size = 500}}) => ({
    selectedRowKeys: ids && ids[name],
    dataSource: source,
    filter: source && source.filter,
    loading: loading ? loading[name] : false,
    size
}));

const mapDispatchToProps = dispatchToProps(({dispatch, ownProps: {onSelect, onSelectAll, onRemove, name, rowKey, noClearSelect, type}, store, sk}) => ({

    onSelect: (record, selected, selectedRows) => {
        let ids_;
        const id = getRowKey(rowKey, record);
        if (noClearSelect && type === "checkbox") {
            const {ids} = store.getState()[sk];
            ids_ = (ids && ids[name]) || [];
            selected ? ids_.push(id) : ids_ = remove(ids_, [id]);
        } else {
            ids_ = selectedRows.map((v) => getRowKey(rowKey, v));
        }
        (!onSelect || !onSelect(record, selected, selectedRows, id, ids_)) && dispatch(select([...ids_], name));
    },

    onSelectAll: (selected, selectedRows, changeRows) => {
        let ids_;
        if (noClearSelect) {
            const {ids} = store.getState()[sk];
            ids_ = (ids && ids[name]) || [];
            const arr = changeRows.map(item => getRowKey(rowKey, item));
            selected ? ids_ = add(ids_, arr) : ids_ = remove(ids_, arr);
            dispatch(select(ids_, name));
        } else {
            ids_ = selectedRows.map((v) => getRowKey(rowKey, v));
        }
        (!onSelectAll || !onSelectAll(selected, selectedRows, changeRows, ids_)) && dispatch(select(ids_, name));
    },

    emptySelect: () => {
        dispatch(select([], name))
    },

    onUpdate: () => {
        populatePermission();
    },

    onRemove: () => {
        onRemove && onRemove();
        dispatch(deleteMore([name, ["ids", name]], sk));
    }
}));

export default connect(mapStateToProps, mapDispatchToProps)(ExtTable);
