import React from 'react';
import {connect} from "react-redux";
import {Modal} from 'antd';
import {dispatchToProps, stateToProps} from "../tools";
import {close, deleteMore} from "../actions";

const ImgModal = ({visible, title, handleCancel, imageUrl}) => (
    <Modal
        visible={visible}
        title={title}
        onCancel={handleCancel}
        onOk={handleCancel}
    >
        {imageUrl ? <img alt="title" style={{width: '100%'}} src={imageUrl}/> : null}
    </Modal>
);

const mapStateToProps = stateToProps(({state, ownProps: {name}}) => ({
    visible: state.visible && state.visible[name],
    imageUrl: state.modelData && state.modelData[name] && state.modelData[name].imageUrl,
    title: state.modelData && state.modelData[name] && state.modelData[name].title
}));

const mapDispatchToProps = dispatchToProps(({dispatch, ownProps: {name, group}}) => ({

    handleCancel: () => {
        dispatch(close(name));
        dispatch(deleteMore([["modelData", name, "imageUrl"], ["modelData", name, "title"]], group));
    },

}));

export default connect(mapStateToProps, mapDispatchToProps)(ImgModal);
