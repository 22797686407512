export default ({dispatch}) => (next) => (action) => {
	
	if(typeof action === 'function') {
		const generator = action(dispatch);
		generator.next();
		generator.next(generator);
		return action;
	}
	
	return next(action);

};