import {Button, Dropdown, Menu} from "antd";
import React, {cloneElement} from "react";

const MoreDropdown = ({menuList = [], group}) => {

    let menuItem = menuList.map((obj, i) => (
        <Menu.Item key={i++}>
            {cloneElement(obj, {group})}
        </Menu.Item>
    ));

    const menu = (
        <Menu className='tableDropdown'>
            {menuItem}
        </Menu>
    );

    return (
        <Dropdown overlay={menu} placement="bottomLeft">
            <Button type='link'>更多<span className="iconfont icon-zhedie"></span></Button>
        </Dropdown>
    )
};

export default MoreDropdown;