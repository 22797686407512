import {message, Modal} from "antd";
import {customTipShow, customTipHide} from './actions';
import store from '../store.js';

export const messageMethod = (text, type = "success", time = 1500) => {

	store.dispatch(customTipShow({
		'text': text,
		'type': type
	}));
	setTimeout(function(){
		store.dispatch(customTipHide());
	}, time);

    // const type_ = "success", duration_ = 3;
    // if(params1 instanceof Object){
    //     const {content, type = type_, duration = duration_, key = content} = params1;
    //     return message[type]({content, duration, key});
    // }

    // const type = type2 || type_;
    // const content = params1;
    // return message[type]({content, duration: duration_, key: content});

};

export const deleteModal = (onOk) => {
    Modal.confirm({
        title: '确认',
        content: '确定删除数据吗?',
        onOk
    });
};