import React from 'react';
import {Collapse, Divider} from 'antd';
import './formBlock.css';

const {Panel} = Collapse;

const FormBlock = (props) => {
    const {children, header} = props;
    return <Collapse bordered={false} defaultActiveKey={['1']} expandIconPosition="right">
        <Panel header={<Divider orientation="left">{header}</Divider>} key="1">
            {children}
        </Panel>
  </Collapse>
}

export default FormBlock;