export const ADD = "add";
export const UPDATE = "update";
export const DETAIL = "detail";
export const QUERY = "query";
export const DICT = "dict";
export const MODELDATA = "modelData";
export const MODEL = "model";
export const RECODE = "recode";
export const PRINT = "print";
export const DOWNLOAD = "download";
export const REMOTE = "remote";
export const SEARCH_FORM = "otherFields";
export const FORM_TEMPLATE = "formTemplate";
export const REFRESH_BTN = "refreshBtn";
export const EXPORT_BTN = "exportBtn";
export const FORM_INFO = "formInfo";