import React, {Component} from 'react';
import {connect} from 'react-redux';
import {stateToProps} from '../../utils/tools';
import {routeMap} from '../../routes';
import {resetStore, storeHistory} from '../../enhancer/resetStore';
import "../view.css";
import {FORM_TEMPLATE} from "../actionNames";
import DefaultTemplate from "./template/default";
import FormTemplate from "./template/form";
import CustomTip from '../customTip';

class PageComponent extends Component {
	
	constructor(props) {
		super(props);
	}
	
	static restore = (props, flag) => {
		const {tabAct, reset, group} = props;
		storeHistory();
		console.log(group, reset, flag);
		if(tabAct && tabAct.moPage && (flag || reset == group)) {
			const route = routeMap.get(tabAct.moPage.split("?")[0]);
			resetStore(route.component.reducer, null, group);
		}
	}
	
	componentWillMount() {
		PageComponent.restore(this.props, true);
	}
	
	componentWillUpdate(nextProps) {
		PageComponent.restore(nextProps);
	}
	
	render() {
		let {children, group, template, optionHeight, noOption} = this.props;

		const baseInfo = {group, optionHeight};

        children = [...(Array.isArray(children) ? children : [children]), <CustomTip key={group} group={group}/>]

		switch (template) {
			case FORM_TEMPLATE:
				return <FormTemplate {...baseInfo}>{children}</FormTemplate>;
			default:
				return <DefaultTemplate noOption={noOption} {...baseInfo}>{children}</DefaultTemplate>;
		}
	};
}

const mapStateToProps = stateToProps(({state, glState}) => ({
    tabAct: glState['tabAct'],
	reset: glState['reset']
}));


export default connect(mapStateToProps)(PageComponent);