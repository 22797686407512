import {genIdx} from '../utils/tools';
import {loading} from '../utils/actions';
import store from '../store';
import {push} from 'connected-react-router';
import {Modal} from 'antd';
import sysConf from '../utils/sysConfig';

const {global, stateKey, filter} = sysConf;

const urls = new Map();

const handleError = (code, msg, seqId, dispatch, url, fail) => {
	if(seqId !== urls.get(url)) {
		return;
	}

	if(!code) {
		return;
	}

	fail && fail();

	switch(code) {
		case 488: dispatch(push('/'));break;
		default:
			Modal.error({
				title: '错误',
				content: msg,
				centered: true
			});
	}
};

const dispatchIfValid = (action, seqId, dispatch, url) => {
	if(action && seqId === urls.get(url)) {
		dispatch(action);
	}
};

export const convertObjToFiled = (data) => {
	let temp = {};
	Object.keys(data).forEach(key => {
		let val =  data[key];
		val = {value : val};
		temp = {...temp , [key] : val};
	});

	return temp;
};



const base = (url, opt, success, dispatch, generator, name, cnt, fail) => {
	const seqId = genIdx();
	urls.set(url, seqId);
	const stk = store.getState()[global][stateKey];
	
	name && dispatch(loading(name, true));

	fetch(url, opt).then(resp => Promise.all([resp.status, resp.json()])).then((resp) => {

		const curStk = store.getState()[global][stateKey];
		
		if(stk != curStk) {
			return;
		}
		
		if(resp[0] !== 200) {
			handleError(resp[0], resp[1]['data']['error'], seqId, dispatch, url, fail);
			name && dispatch(loading(name, false));
			return;
		}
		
		cnt && cnt --;
		let act = resp[1] && resp[1].data ? success(resp[1], cnt) : null;
		
		if(act) {
			if(act.type === 'd') {
				act = act(name);
				act['name'] = name;
			}
			dispatchIfValid(act, seqId, dispatch, url, fail);
		}
		
		name && (cnt != undefined && cnt != null ? cnt <= 0 : true) && dispatch(loading(name, false));
		generator.next(resp[1]);
	}).catch(error => {
		handleError(588, error.message, seqId, dispatch, url);
		name && dispatch(loading(name, false));
	});

}

const appendToken = (url) => {

	const gl = store.getState()[global];

	if(!gl)
		throw new Error("Global should not be null");

	if(gl['token']) {
		url += (url.search(/\?/) === -1 ? '?' : '&') + "admin_token=" + gl['token'];
	}

	return url;
}


export const get = ({url, success, dispatch, generator, data, name, loading, fail}) => {
	url = appendToken(url);
	
	if (data) {
		const pagination = data.pagination;
		const paramsArray = []; //拼接参数

		Object.keys(data).forEach(key => {

			if(key === 'pagination') {
				const length = pagination && pagination.pageSize ? pagination.pageSize : 10;
				const start = ((pagination && pagination.current ? pagination.current : 1) - 1) * length;

				paramsArray.push('start=' + start);
				paramsArray.push('length=' + length);
			} else if(Array.isArray(data[key])){
				let dt = "";
				data[key].forEach(v => {
					dt += key + "=" + v + "&";
				});
				paramsArray.push(dt.replace(/.$/g, ""));
			} else {
				data[key] != null && data[key] != undefined && paramsArray.push(key + '=' + data[key]);
			}

		});
		
		const state = store.getState();
		const glState = state[global];
		const sk = glState[stateKey];
		
		url += (url.search(/\?/) === -1 ? '?' : paramsArray.length <= 0 ? '' : '&') + paramsArray.join('&');
		
		let f = null;
		if(name && state[sk][name] && (f = state[sk][name][filter])) {
			const keys = Object.keys(f);
			url += keys.length > 0 ? ('&' + keys.reduce((prev, cur) => (
				f[cur] != null && f[cur] != undefined ? (
					prev + (
						typeof f[cur] === "string" ? cur + '=' + f[cur].trim() :
						//当拼接的参数为数组时
						Array.isArray(f[cur]) && f[cur].length > 0 ? f[cur].reduce((total, node) => (
							total + cur + '=' + (node != null && node != undefined ? node : "") + '&'
							), "").replace(/.$/g, "") :
						cur + '=' + f[cur]
					) + '&'
				) : prev
			), "").replace(/.$/g, "")) : '';
		}
	}

	const opt = {
		method: 'GET',
		mode:'cors'
	};

	base(url, opt, success, dispatch, generator, name, loading, fail);
};

export const post = ({url, success, dispatch, generator, data, type, name}) => {
	url = appendToken(url);

	let params = null;
	let contentType = "application/";
	if(type === 'json') {
		contentType = contentType + "json";
		params = JSON.stringify(data);
	} else {
		let paramsArray = [];
		contentType = contentType + "x-www-form-urlencoded";
		Object.keys(data).forEach(key => paramsArray.push(key + '=' + data[key]));
		params = paramsArray.join('&');
	}

	const opt = {
		method: 'POST',
		headers: {
			"Content-Type": contentType
		},
		mode:'cors',
		body: params
	};

	base(url, opt, success, dispatch, generator, name);
};

export const put = ({url, success, dispatch, generator, data, type, name, loading}) => {
	url = appendToken(url);

	let params = null;
	let contentType = "application/";
	if(type === 'json') {
		contentType = contentType + "json";
		params = JSON.stringify(data);
	} else {
		let paramsArray = [];
		contentType = contentType + "x-www-form-urlencoded";
		Object.keys(data).forEach(key => paramsArray.push(key + '=' + data[key]));
		params = paramsArray.join('&');
	}

	const opt = {
		method: 'PUT',
		headers: {
			"Content-Type": contentType
		},
		mode:'cors',
		body: params
	};

	base(url, opt, success, dispatch, generator, name, loading);
};

export const del = ({url, success, dispatch, generator, data, name}) => {
	url = appendToken(url);
	const paramsArray = []; //拼接参数

	Object.keys(data).forEach(key => {
		const value = Array.isArray(data[key]) ? data[key].join(`&${key}=`) : data[key];
		paramsArray.push(key + '=' + value);
	});

	url += (url.search(/\?/) === -1 ? '?' : paramsArray.length <= 0 ? '' : '&') + paramsArray.join('&');

	const opt = {
		method: 'DELETE',
		mode:'cors'
	};

	base(url, opt, success, dispatch, generator, name);
};