import React from 'react';
import {Spin} from 'antd';
import {stateToProps} from "../tools";
import {connect} from "react-redux";

const ExtSpin = ({children, loading, wrapperClassName, className}) => {

    return <Spin spinning={loading} delay={500} wrapperClassName={wrapperClassName} className={className}>
        {children}
    </Spin>
};

const mapStateToProps = stateToProps(({ownProps: {name}, state: {loading}}) => ({
    loading: loading && loading[name] ? loading[name] : false,
}));


export default connect(mapStateToProps)(ExtSpin);