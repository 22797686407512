import React, {useEffect} from 'react';
import {Input} from 'antd';
import {Spin, Tree} from '../../index';
import {dispatchToProps, stateToProps} from "../../tools";
import {connect} from "react-redux";
import {init, search} from "../actions";

const {Search} = Input;

const SearchTree = ({onInitTree, needInit, onSearch, placeholder = "请输入查询条件", treeInfo: {loadedKeys = []}, name, style,notInit, ...otherProps}) => {

    useEffect(() => {
        notInit === undefined && onInitTree();
    }, [needInit]);

    return (
        <Spin name={name}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <Search className="sample-search" placeholder={placeholder} onPressEnter={onSearch} onSearch={onSearch}
                        allowClear/>
                <div style={style ? style : {width: '200px', height: 'auto', overflow: 'auto', flex: 1}}>
                    <Tree
                        {...otherProps}
                        name={name}
                        height={700}
                        loadedKeys={loadedKeys}
                        notInit={notInit === undefined ? true : notInit}
                    />
                </div>
            </div>
        </Spin>
    )
};

const mapStateToProps = stateToProps(({state: {treeInfo, needInit}, ownProps: {name}}) => ({
    treeInfo: (treeInfo && treeInfo[name]) || {},
    needInit: needInit && needInit[name],
}));

const mapDispatchToProps = dispatchToProps(({dispatch, ownProps: {name, initUrl, loadUrl, searchUrl, param = {}, initParam = {}, searchParam = {}}}) => ({

    onInitTree: () => {
        dispatch(init(initUrl || loadUrl, name, {...param, ...initParam}));
    },

    onSearch: value => {
        value = value.target ? value.target.value : value;
        if (value) {
            dispatch(search(value, searchUrl, name, {...param, ...searchParam}));
        } else {
            dispatch(init(initUrl || loadUrl, name, {...param, ...initParam}));
        }
    },
}));

export default connect(mapStateToProps, mapDispatchToProps)(SearchTree);
