import React from 'react';
import {connect} from 'react-redux';
import {DraggableModal, DraggableModalProvider} from 'ant-design-draggable-modal';
import 'antd/dist/antd.css';
import 'ant-design-draggable-modal/dist/index.css';
import {close, deleteOne} from '../actions';
import {dispatchToProps, stateToProps} from '../tools';
import {Spin} from "antd";
import base from "../base";
import {MODELDATA} from "../actionNames";

const ExtModal = base(({children, title, name, onOk, onHandleCancel, onCancel, visible, width, height, okText, loading}) => (
    <div className="span-model-par">
        <DraggableModalProvider>
            <DraggableModal getContainer={false} mask={true} maskClosable={false} title={title} visible={visible}
                            onOk={onOk} initialWidth={width} initialHeight={height}
                            onCancel={onHandleCancel(name, onCancel)} okText={okText}>
                {children}
            </DraggableModal>
        </DraggableModalProvider>
        {loading ?
            <div className="span-par">
                <Spin spinning={true} delay={500} className="span"/>
            </div> : null
        }
    </div>
));

const mapStateToProps = stateToProps(({state, ownProps: {name}}) => ({
    visible: state.visible && state.visible[name],
    loading: state["loading"] ? state["loading"][name] : false,
}));

const mapDispatchToProps = dispatchToProps(({dispatch, ownProps: {name}}) => ({
    onHandleCancel: (name, cancelMethon) => () => {
        cancelMethon && typeof cancelMethon == 'function' && cancelMethon();
        dispatch(close(name));
    },

    onRemove: () => {
        dispatch(deleteOne([MODELDATA, name]));
    }
}));

export default connect(mapStateToProps, mapDispatchToProps)(ExtModal);