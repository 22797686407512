import React, {useState} from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
    toolbar: {
        container: [
            [{ header: [1, 2,3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike', ],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ 'align': [] }],
            [{ 'color': [] }, { 'background': [] }],
            ['link', 'image'],
            ['clean'],
        ],
    },
};

const ReactQuillComponent = ({...otherProps}) => {

    return (
        <ReactQuill style={{height:"300px"}} readOnly={false} modules={modules} {...otherProps}>
            {/*<div className={className}/>*/}
        </ReactQuill>
    );
};

export default ReactQuillComponent;