import React from 'react';
import {connect} from 'react-redux';
import {Button, Col, Row} from "antd";
import {RollbackOutlined, SaveOutlined} from "@ant-design/icons";
import {clSubMenu} from "../../../actions";
import {dispatchToProps, stateToProps} from '../../../utils/tools';
import ExtForm from '../index'
import './controlButton.css'

const PageForm = ({onSubmit, onClose, children, headButtons, group, active, leftHide, okTest, ...OtherProps}) => {
    const baseInfo = {group, active};
	var lh = leftHide ? leftHide : false;
	var w = lh ? 'calc(100% - 64px - 16px)' : 'calc(100% - 256px - 16px)';
    return (
        <div>
            <div className="actionBarButtonGroup">
                {headButtons}
            </div>
            <ExtForm {...baseInfo} {...OtherProps}>
                {children}
                <Row>
                    <Col className="form_control_btn" style={{'width': w}}>
                        {
                            onSubmit ? <Button icon={<SaveOutlined/>} type="primary" className="btn-custom" htmlType="submit">
                                {okTest ? okTest : "提交"}
                            </Button> : null
                        }
                        <Button className="btn-custom" icon={<RollbackOutlined/>} onClick={onClose}>
                            关闭
                        </Button>
                    </Col>
                </Row>
            </ExtForm>
        </div>
    );
}

const mapStateToProps = stateToProps(({glState}) => ({
    leftHide: glState['hide']
}));

const mapDispatchToProps = dispatchToProps(({dispatch, ownProps: {onSubmit}, store}) => ({
    onFinish: onSubmit,

    onClose: (event) => {
        dispatch(clSubMenu(store.getState()["global"]["tabAct"]["moId"]));
    },
}));

export default connect(mapStateToProps, mapDispatchToProps)(PageForm)