export const TOGGLEMODAL = 'toggleModal';
export const CHANGEFORM = 'changeForm';
export const LOADING = 'loading';
export const DATA = 'data';
export const FAILURE = 'failure';
export const SETFIELD = 'setField';
export const CHANGEOTHERFORM = 'changeOtherForm';
export const SETOTHERFIELD = 'setOtherField';
export const SETOPTIONS = 'setOptions';
export const SETDICT = 'setDict';
export const MODEL_TYPE = "modelType";
export const ROW_KEYS = 'rowKeys';
export const PAGE_INFO = 'pageInfo';
export const SELECT = 'select';
export const NEED_INIT = 'needInit';
export const TREE_INFO = 'treeInfo';
export const SEARCH = 'search';
export const OTHER_VALUE = 'otherValue';
export const FORM = 'form';
export const DELETE_STATE = "deleteState";
export const SET_TREE_SHOW = 'setTreeShow';
export const OPTION_TOGGLE = 'optionToggle';
export const INIT_OPT_AREA = 'initOptArea';
export const CHANGE_DELETE_FILE = 'changeDeleteFile';
export const CHANGE_UPLOAD_FILE = 'changeUploadFile';
export const ADD_FORM_INFO = 'addFormInfo';




