import moment from "moment";


export const date2String = (datetime, noTime) => {
    const year = datetime.getFullYear();
    const month = addPreZero(datetime.getMonth() + 1);
    const date = addPreZero(datetime.getDate());
    const hour = addPreZero(datetime.getHours());
    const minute = addPreZero(datetime.getMinutes());
    const second = addPreZero(datetime.getSeconds());
    return year + "-" + month + "-" + date + (!noTime ? (" " + hour + ":" + minute + ":" + second) : "");
}

export const addPreZero = (t) => {
    return t < 10 ? ('0' + t) : t;
}

export const getFirstDayOfMonth = (noTime) => {
    const date = new Date();
    date.setDate(1);
    date.setHours(0, 0, 0, 0);
    return date2String(date, noTime);
};


export const currentTime = () => (
    moment().format('YYYY-MM-DD h:mm:ss')
);

export const currentDay = () => (
    moment().format('YYYY-MM-DD')
);

export const momentToString = (momentObj) => {
    const stringObj = {};
    for (let key in momentObj) {
        if(Array.isArray(momentObj[key])){
            stringObj[key] = [];
            for(let x in momentObj[key]){
                stringObj[key][x] = momentObj[key][x] && momentObj[key][x].format ? momentObj[key][x].format('YYYY-MM-DD HH:mm:ss') : momentObj[key][x];
            }
        }else{
            stringObj[key] = momentObj[key] && momentObj[key].format ? momentObj[key].format('YYYY-MM-DD HH:mm:ss') : momentObj[key];
        }
    }
    return stringObj;
}

export const stringObjToMomentObj = (stringObj, keys) => {
    const momentObj = {};
    if (keys) {
        keys.forEach(key => {
            momentObj[key] = moment(stringObj[key]);
        });
    } else {
        for (let key in stringObj) {
            if(Array.isArray(stringObj[key])){
                momentObj[key] = [];
                for(let index in stringObj[key]){
                    momentObj[key].push(moment(stringObj[key][index]));
                }
            }else{
                momentObj[key] = moment(stringObj[key]);
            }
        }
    }
    return momentObj;
}

//时间戳转String
export const timestampToString = (time = +new Date()) => {
    var date = new Date(time + 8 * 3600 * 1000); // 增加8小时
    return date.toJSON().substr(0, 19).replace('T', ' ');
}

//String转时间戳
export const stringToTimestamp = (time) => {
    time = time.substring(0,19);
    time = time.replace(/-/g,'/'); //必须把日期'-'转为'/'
    const timestamp = new Date(time).getTime();
    return timestamp;
};
