import messages from "../../messages";
import {get} from "../../middlewares/request";
import {failure, setDict} from "../../utils/actions";

export const getDict = (diTypes) => {
    return function* (dispatch) {

        const url = messages['urls']['supervision'] + '/dict/all';

        let generator = yield;

        yield get({
            url,
            success: resp => {
                dispatch(setDict(resp.data));
            },
            fail: error => failure(error),
            dispatch,
            generator,
            data: {diType: diTypes}
        });

    };
};

export const getDictChildren = (diValues) => {
    return function* (dispatch) {

        const url = messages['urls']['supervision'] + '/dict/newChildren';

        let generator = yield;

        yield get({
            url,
            success: resp => {
                dispatch(setDict(resp.data));
            },
            fail: error => failure(error),
            dispatch,
            generator,
            data: {diValues}
        });

    };
};


export const getDictByDiValues = (diValues) => {
    return function* (dispatch) {

        const url = messages['urls']['supervision'] + '/dict/newChildren';

        let generator = yield;

        yield get({
            url,
            success: resp => {
                dispatch(setDict(resp.data));
            },
            fail: error => failure(error),
            dispatch,
            generator,
            data: {diValues: diValues}
        });

    };
};
