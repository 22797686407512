import React, {forwardRef, useEffect} from 'react';
import {connect} from "react-redux";
import {Button, Upload} from 'antd';
import {UploadOutlined} from "@ant-design/icons/es/icons";
import {dispatchToProps} from "../tools";
import base from "../base";
import ImgModal from "./imgModal";
import {
    addDeleteFiles,
    addUploadFiles,
    deleteMore,
    getImg,
    getOssData,
    open,
    removeUploadFiles,
    setInit
} from "./actions";

const OssUpload = base(({onChange, listType, beforeUpload, onDelete, displayImg, onPreview, value = {}, group, name}) => {

    useEffect(() => {
        value && value.fileList && displayImg(value);
    }, [value]);

    return (
        <div>
            <Upload
                listType={listType}
                beforeUpload={beforeUpload}
                onChange={onChange}
                fileList={value.fileList || []}
                onRemove={onDelete}
                onPreview={onPreview}
                name='file'
            >
                <Button>
                    <UploadOutlined/> Upload
                </Button>
            </Upload>
            {
                listType === "picture" || listType === "picture-card" ?
                    <ImgModal name={name} group={group}/>
                    : null
            }
        </div>
    )
});

const mapDispatchToProps = dispatchToProps(({dispatch, ownProps: {path, name, maxSize, onChange, getRealName, listType}, store, sk}) => ({

    displayImg: (value) => {
        const {needInit} = store.getState()[sk];
        if ((listType !== "picture" && listType !== "picture-card") || (needInit && needInit[name])) {
            return;
        }

        getOssData(path).then(([{ossClient, folder}]) => {
                let i = 0;
                value.fileList.forEach(item => {
                    if (item.loading && item.realName) {
                        item.thumbUrl = ossClient.signatureUrl(folder + item.realName);
                        item.url = ossClient.signatureUrl(folder + item.realName);
                        i++
                    }
                });
                i > 0 && onChange({...value});
            }
        );
        dispatch(setInit(name, true));
    },

    beforeUpload: (file) => {

        file.realName = (getRealName && getRealName(file.name, file)) || file.name;

        dispatch(addUploadFiles(name, file, path, true));

        return false;
    },

    onDelete: file => {

        file.realName = (getRealName && getRealName(file.name, file)) || file.name;

        file.isOriginal ? dispatch(addDeleteFiles(name, file, path)) : dispatch(removeUploadFiles(name, file, path));

        return true;
    },

    onChange: (value) => {

        const {file} = value;

        if (file.status !== "removed" && false) {
            return;
        }

        onChange(value);
    },

    onRemove: () => {
        dispatch(deleteMore([["needInit", name], ["fileInfo", name]]));
    },

    onPreview: (file) => {
        getOssData(path).then(([{ossClient, folder}]) => {
            dispatch(open(name, {imageUrl: ossClient.signatureUrl(folder + file.realName), title: file.name}));
        });
    }
}));

export default connect(null, mapDispatchToProps, null, {forwardRef: true})(forwardRef(OssUpload));

export {getOssData, getImg}
