import React, {useEffect, useState} from 'react';
import {Button, Card, Input, Menu} from 'antd';
import {Map} from 'react-amap';
import {AMAP_WEB_SERVER_KEY, getAddressGeo, getAddressRegeo, getRound} from './amap';
import "./add.css";

const {Search} = Input;

const ActionBar = ({setCenter, mapCenterAddr}) => (
    <div className="tableSelectActionBar">
        查询地址：
        <Search
            placeholder="如：福州市台江区XX路"
            onSearch={value => getAddressGeo({address: value, batch: true}).then(({geocodes}) => {
                const point = geocodes[0].location.split(',');
                const longitude = point[0];
                const latitude = point[1];
                setCenter({longitude, latitude});
            })}
            style={{width: 200}}
        />
        <span className="mapAddress-now">当前地图地址：{mapCenterAddr}</span>
    </div>
);

const ExCard = ({roundList, onChange, setLabel, visibleDropdown}) => (
    <Card title="附近的地址" bordered={false} className="round_select" bodyStyle={{flex: 1, overflowY: 'auto'}}>
        {
            roundList ? roundList.map((v, i) => {
                const point = v.location.split(',');
                return <div key={v.id}
                            className="borderbottom"
                            onClick={() => {
                                //赋值
                                onChange({
                                    latitude: point[1],
                                    longitude: point[0],
                                    address: v.address
                                });
                                setLabel(v.address);
                                //收起下拉框
                                visibleDropdown(false);
                            }}>
                    <div>名称：{v.name}</div>
                    <p>地址：{v.address}</p>
                </div>
            }) : <p>附近无地址</p>
        }
    </Card>
)

//地址下拉控件内，下拉框的内容
const MenuDropdown = ({onChange, value, visibleDropdown, setLabel}) => {
    //onChange：控件的onChange方法，用来修改fields的值(选中之后触发)
    //value：fields的值
    //visibleDropdown：下拉框的显示隐藏

    //地图实例
    const [map, setMap] = useState(null);
    //周边地址的list
    const [roundList, setRoundList] = useState(null);
    //地图中心点坐标
    const [center, setCenter] = useState(null);
    //中文地址
    const [mapCenterAddr, setMapCenterAddr] = useState('');

    const events = {
        created: (ins) => {
            //得到地图实例
            setMap(ins);
        },
        moveend: () => {
            //获取中心点坐标
            const center = map.getCenter();

            const {lng, lat} = center;
            //根据中心点坐标得到当前address
            getAddressRegeo({location: `${lng},${lat}`}).then(res => {
                const address = res.regeocode.formatted_address
                onChange({latitude: lat, longitude: lng, address});
                setLabel(address);
                setMapCenterAddr(address);
            });
            //根据中心点坐标得到周围的信息列表
            getRound({location: `${lng},${lat}`}).then(res => {
                setRoundList(res.pois);
            });
        }
    }

    useEffect(() => {
        //设置默认时，优先显示默认值所在位置
        if (value && value.longitude && value.latitude) {
            setCenter({longitude: value.longitude, latitude: value.latitude});
            getRound({location: `${value.longitude},${value.latitude}`}).then(res => {
                setRoundList(res.pois);
            });
        }
    }, []);


    return <Menu>
        <div className="tableSelectBlock" style={{width: '1000px'}}>
            <ActionBar setCenter={setCenter} mapCenterAddr={mapCenterAddr}/>
            <div className="mapBlock">
                <div className="amap-div">
                    <Map
                        amapkey={AMAP_WEB_SERVER_KEY}
                        events={events}
                        zoom={16}
                        center={center}
                        resizeEnable={true}
                    />
                    <span className="iconfont iconzuobiao center-point"/>
                </div>
                <ExCard roundList={roundList} onChange={onChange} setLabel={setLabel}
                        visibleDropdown={visibleDropdown}/>
            </div>
        </div>
        <div className='ant-modal-footer'>
            <Button onClick={() => visibleDropdown(false)}>取消</Button>
            <Button type="primary" onClick={() => visibleDropdown(false)}>确认</Button>
        </div>
    </Menu>
};

export default MenuDropdown;