import React from 'react';
import {connect} from "react-redux";
import {dispatchToProps, stateToProps} from "../../tools";
import {close} from "../../actions";
import {FORM} from "../../actionTypes";
import ExtForm from '../index'
import ExtModal from "../../modal";

const ExtModalForm = ({onFinish, children, title, name, okText, width, height, form = {}, modelData = {}, group, active, ...otherProps}) => (
    <ExtModal title={title || modelData.title} name={name} onOk={form.submit} okText={okText} width={width}
              height={height} {...{group, active}}>
        <ExtForm {...otherProps} onFinish={onFinish} name={name} {...{group, active}}>{children}</ExtForm>
    </ExtModal>
);

const mapStateToProps = stateToProps(({state: {modelData, [FORM]: form}, ownProps: {name}}) => ({
    modelData: modelData && modelData[name],
    form: form && form[name]
}));

const mapDispatchToProps = dispatchToProps(({dispatch, ownProps: {onSubmit, name}}) => ({
    onFinish: onSubmit || (() => {
        dispatch(close(name));
    }),
}));


export default connect(mapStateToProps, mapDispatchToProps)(ExtModalForm);