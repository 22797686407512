import React from 'react';
import {Button, Col, Row} from 'antd';
import {connect} from "react-redux";
import {dispatchToProps, stateToProps} from "../../tools";
import {FORM} from "../../actionTypes";
import {QUERY, SEARCH_FORM} from "../../actionNames";
import {deleteOne, optionToggle, setData} from "../../actions";
import ExtForm from '../index'
import {initOptArea} from "../../../html/mgr_hnt/hntRecordTask/actions";
import base from "../../base";

let defaultOptionAreaHeight = 156;
let minOptionAreaHeight = 64;

const SearchForm = base(({children, onReset, onSearch, optionShow, onOptionToggle, name = SEARCH_FORM, childrenSize = 2, isMore, onInit, group, tableName, ...otherProps}) => {

    let btnGroupLength = 0;
    let span;
    let floorCount = 0;

    children = Array.isArray(children) ? children : [children];

    if (isMore === undefined) {
        isMore = true;
        (name !== SEARCH_FORM || children.length <= childrenSize) && (isMore = false);
    }

    const colStyle = !isMore || optionShow ? {display: 'block'} : {display: 'none'};

    const cols = children.map((c, i) => {
        span = (c.props.span || 8);
        btnGroupLength += span;
        return i >= 2 ? React.cloneElement(c, {
            style: colStyle,
            key: group + i,
            span
        }) : React.cloneElement(c, {key: group + i, span});
    });

    floorCount = (btnGroupLength / 24) + 1;
    // console.log(Math.floor(floorCount));
    defaultOptionAreaHeight = floorCount * (32 + 14);
    // console.log(defaultOptionAreaHeight);

    btnGroupLength = 24 - btnGroupLength % 24;
    btnGroupLength = btnGroupLength < 8 ? 24 : btnGroupLength;

    return (
        <ExtForm
            className="ant-advanced-search-form actionBarForm"
            name={name}
            group={group}
            {...otherProps}
        >
            <Row>
                {cols}
                <Col span={optionShow === true || optionShow === undefined || !isMore ? btnGroupLength : 8}
                     style={{textAlign: 'right'}}>
                    <Button className="btn-custom" type="primary" htmlType="submit">
                        查询
                    </Button>
                    <Button className="btn-custom" style={{marginLeft: 8}} onClick={onReset}>
                        重置
                    </Button>
                    <Button style={{'display': isMore ? 'inlineBlock' : 'none'}} type="link" onClick={onOptionToggle}>
                        {optionShow ? '收起' : '展开'}
                        {optionShow ?
                            <span className="iconfont icon-zhedie1"/> :
                            <span className="iconfont icon-zhedie"/>
                        }
                    </Button>
                </Col>
            </Row>
        </ExtForm>
    );
});

const mapStateToProps = stateToProps(({state}) => ({
    optionShow: state['optionShow'],
}));

const mapDispatchToProps = dispatchToProps(({dispatch, ownProps: {name = SEARCH_FORM, onSearch, children, childrenSize = 2, isMore, group, tableName = QUERY}, sk, store}) => ({
    onInit: () => {
        if ((isMore || (isMore === undefined && name === SEARCH_FORM)) && children.length > childrenSize) {
            dispatch(setData((
                    Array.isArray(children) ? children : [children]).map(item => item.props.children.props.name).slice(0, Math.min(childrenSize, children.length)),
                "_keys"));
            dispatch(initOptArea(defaultOptionAreaHeight, minOptionAreaHeight));
        }
    },

    onReset: () => {
        const {resetValues: {[name]: resetValues = {}}, [FORM]: {[name]: form}} = store.getState()[sk];
        form.setFieldsValue(resetValues);
    },

    onFinish: (v) => {
        const {optionShow, _keys: keys} = store.getState()[sk];
        const state = store.getState()[sk];
        let pagination = tableName && state[tableName] && state[tableName].pagination;
        pagination = pagination && {pageSize: pagination.pageSize};
        onSearch(keys && !optionShow ? getNewValues(v, keys) : v, pagination);
    },

    onOptionToggle: () => {
        dispatch(optionToggle())
    },

    onRemove: () => {
        dispatch(deleteOne("_keys", group));
    }
}));

const getNewValues = (v, keys) => {
    const newValue = {};
    keys.forEach(item => {
        if (!item) return;
        if (item instanceof Array) {
            let temp = v;
            let tempNew = newValue;
            for (let i = 0; i < item.length; i++) {
                temp = temp(item[i]);
                if (temp && temp instanceof Object) {
                    tempNew[item[i]] = {};
                } else {
                    tempNew[item[i]] = temp;
                }
            }
            return;
        }
        newValue[item] = v[item];
    });
    return newValue;
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
