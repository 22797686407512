import {
	TOKEN,
	STAFF,
	CLEAR,
	SELMENU,
	SEL,
	SELSUBMENU,
	CLSUBMENU,
	SELTAB,
	TOGGLE,
	TOGGLETAB,
	CLALLTAB,
	CLOTHERTAB,
	CLOSETABBUT,
	MOVE,
	LOADING,
	WINDOWRESIZE,
	ACTIONBAR,
	PARAMS,
	NEWTAB,
	SELECTMORE,
	RESTORE_TAB,
	SHOWMORE,
	SET_OSS_DATA
} from './actionTypes';
import {loading} from "./utils/actions";

export default 'global';

export const token = (t) => ({
	type: TOKEN,
	val: t
});

export const staff = (s, t) => ({
	type: STAFF,
	val: s,
	token: t
});

export const clear = () => ({
	type: CLEAR
});

export const selMenu = (m, s) => ({
	type: SELMENU,
	val: m,
	state: s
});

export const selSubMenu = (m) => ({
	type: SELSUBMENU,
	val: m
});

export const createNewTab = (id, title, url, params) => ({
	type: NEWTAB,
	id: id,
	title: title,
	url: url,
	params
});

export const clSubMenu = (m) => ({
	type: CLSUBMENU,
	val: m
});

export const selTab = (m) => ({
	type: SELTAB,
	val: m
});

export const move = (w) => ({
	type: MOVE,
	val: w
});

export const sel = (top) => ({
	type: SEL,
	val: top
});

export const toggle = () => ({
	type: TOGGLE
});

export const toggleTab = () => ({
	type: TOGGLETAB
});

export const clAllTab = () => ({
	type: CLALLTAB
});

export const clOtherTab = () => ({
	type: CLOTHERTAB
});

export const closeTabBut = () => ({
	type: CLOSETABBUT
});

export const windowResize = (s) => ({
	type: WINDOWRESIZE,
	windowResize:s
});

export const actionBar = (b) => ({
	type:ACTIONBAR,
	actionBar:b
});

export const changeParams = (p) => ({
	type:PARAMS,
	params:p
});

export const showMore = (morelist, left) => ({
	type: SHOWMORE,
	moreShow: true,
	list: morelist,
	left: left
});

export const selectMore = (id) => ({
	type: SELECTMORE,
	id: id
});

export const restoreTab = (url) => ({
	type: RESTORE_TAB,
	url
});

export const setOssData = (path, data) => ({
	type: SET_OSS_DATA,
	path,
	data,
});