import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {Form} from 'antd';
import {dispatchToProps, stateToProps} from "../tools";
import {deleteMore, setForm} from "../actions";
import base from "../base"
import PageForm from "./pageForm";
import ModalForm from "./modalForm";
import SearchForm from "./searchForm";
import {FORM} from "../actionTypes";
import {getOssData} from "../ossClient"
import {momentToString} from "../timeTools";

const ExtForm = base(({children, data, otherValue, setForm, setData, onRemove, group, active, ...otherProps}) => {

    const [form] = Form.useForm();

    useEffect(() => {
        setForm(form);
    }, []);

    useEffect(() => {
        data && setData(form, data);
    }, [data]);

    useEffect(() => {
        otherValue && form.setFieldsValue(otherValue);
    }, [otherValue]);

    return <Form {...otherProps} form={form}>{children}</Form>
});

const mapStateToProps = stateToProps(({ownProps: {name}, state: {[name]: data, otherValue}}) => ({
    data,
    otherValue: otherValue && otherValue[name],
}));

const mapDispatchToProps = dispatchToProps(({dispatch, ownProps: {name, onFinish, group}, store, sk}) => ({

    setForm: (form) => {
        dispatch(setForm(name, form, {...form.getFieldsValue()}));
    },

    setData: (form, data) => {
        const {resetValues: {[name]: resetValues = {}}} = store.getState()[sk];
        form.setFieldsValue({...resetValues, ...data});
    },

    onFinish: (value) => {
        if (onFinish) {

            const {global} = store.getState();
            const {[name]: fields = {}, otherValue = {}, treeInfo, fileInfo} = store.getState()[sk];
            const v = momentToString({...(otherValue[name] || {}), ...value});

            treeSubmit(v, treeInfo);
            const {before, after} = uploadSubmit(v, fileInfo, global);

            if (before) {
                before.then(() => {
                    onFinish(v, fields, after);
                });
            } else {
                onFinish(v, fields, after);
            }
        }
    },

    onRemove: () => {
        dispatch(deleteMore([name, [FORM, name], ["otherValue", name]]));
    },

}));

const treeSubmit = (v, treeInfo) => {
    treeInfo && Object.keys(treeInfo).forEach(key => {
        v[key] = v[key] && treeInfo[key] && treeInfo[key]['checkedNodes'] ? treeInfo[key]['checkedNodes'] : v[key];
    });
};

const uploadSubmit = (v, fileInfo, global) => {
    let deleteArr = [], uploadObj = {}, path_;

    fileInfo && Object.keys(fileInfo).forEach(key => {
        if (fileInfo[key]) {
            const {path, deleteFiles, uploadFiles} = fileInfo[key];
            path_ = path;
            deleteFiles && (deleteArr = [...deleteArr, ...deleteFiles]);
            uploadFiles && (uploadObj = {...uploadObj[path], ...uploadFiles});
        }
    });

    if (!path_) {
        return {};
    }

    const before = deleteArr.length > 0 && getOssData(path_).then(([{ossClient, folder}]) => (
            Promise.all(deleteArr.map(file =>
                ossClient.delete(folder + (file.realName || file.name))
            ))
        )
    );

    const uploadKeys = Object.keys(uploadObj);
    const after = uploadKeys.length > 0 && (() => (
        getOssData(path_).then(([{ossClient, folder}]) => (
            Promise.all(uploadKeys.map(key =>
                ossClient.put(folder + (uploadObj[key].realName || uploadObj[key].name), uploadObj[key])
            ))
        ))
    ));

    return {before, after};
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtForm);
export {ModalForm, PageForm, SearchForm}