import React, {forwardRef} from "react";
import {connect} from "react-redux";
import {Dropdown, Input} from "antd";
import {dispatchToProps} from "../tools";
import {deleteOne, setInit} from "../actions";
import {NEED_INIT} from "../actionTypes";
import base from "../base"
import MenuDropdown from "./menuDropdown"
import './tableSelect.css';

const TableSelect = base(({onChange, value, placeholder, disable, optionum, onVisibleChange, onRemove, ...otherProps}, ref) => (
   	<Dropdown overlay={<MenuDropdown optionum={optionum} {...otherProps}/>} trigger={['click']} onVisibleChange={onVisibleChange}
              overlayStyle={{height: 'auto'}} placement="bottomCenter">
        <div aria-disabled={"true"}>
            <Input onChange={onChange} value={value} placeholder={placeholder} disabled={!disable ? false : true}
                   allowClear/>
        </div>
    </Dropdown>
));

const mapDispatchToProps = dispatchToProps(({dispatch, ownProps: {onTableChange, name}, sk, store}) => ({

    onVisibleChange: (v) => {
        const {needInit} = store.getState()[sk];
        if (!needInit || !needInit[name]) {
            dispatch(setInit(name, true));
            onTableChange(name)();
        }
    },

    onRemove: () => {
        dispatch(deleteOne([NEED_INIT, name]));
    }

}));


export default connect(null, mapDispatchToProps, null, {forwardRef : true})(forwardRef(TableSelect));
