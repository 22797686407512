import React from "react";
import {connect} from "react-redux";
import {Menu} from "antd";
import {dispatchToProps} from "../tools";
import {otherValue} from "../actions";
import Table from "../table"
import './tableSelect.css';

const getRowProp = (row, tableName) => {
    if (tableName instanceof Array) {
        let prop = row;
        tableName.forEach(item => {
            prop && (prop = prop[item]);
        });
        return prop;
    }
    return row[tableName];
};

const changeObj = (fieldName, obj, value) => {
    if (fieldName instanceof Array) {

        let l = fieldName.length - 1;
        for(let i = 0; i < l; i++){
            !(obj[fieldName[i]] && obj[fieldName[i]] instanceof Object) && (obj[fieldName[i]] = {});

            obj = obj[fieldName[i]];
        }
        obj[fieldName[l]] = value;
        return;
    }
    obj[fieldName] = value;
};

const height = window.innerHeight / 1.8;
const width = window.innerWidth / 2;

const MenuDropdown = ({onChange, onTableChange, type = "radio", optionum, children, ...otherProps}) => (
    <Menu>
        <div className="tableSelectBlock" style={{'width': width, 'height': height}}>
            <div className="tableSelectActionBar">
                {children}
            </div>
            <div className='tableholder' style={{'height':'calc(100% - '+ ((optionum + 1) / 2) * 37 + 'px' +' - 10px)'}}>
				<Table type={type} {...otherProps} onChange={onTableChange}/>
			</div>
        </div>
    </Menu>
);

const mapDispatchToProps = dispatchToProps(({dispatch, ownProps: {fieldNames, tableNames, formName}}) => ({

    onSelect: (record, selected, selectedRows) => {

        const obj = {};

        selectedRows.length > 0 && tableNames.forEach((item, i) => {
            changeObj(fieldNames[i], obj, getRowProp(selectedRows[0], item));
        });

        dispatch(otherValue(formName, obj));

        return true;
    },

}));


export default connect(null, mapDispatchToProps)(MenuDropdown);