import React, {forwardRef, useState} from 'react';
import {Dropdown, Input} from 'antd';
import "./add.css";
import MenuDropdown from "./menuDropdown";


//地址下拉选择控件
const AddressSelect = ({value, disabled, onChange, ...otherProps}) => {

    const [label, setLabel] = useState(value && value.address ? value.address : "");

    //value：fields的值
    //下拉框显示隐藏的状态
    const [visible, setVisible] = useState(false);
    //触发下拉框显示隐藏的事件
    const visibleDropdown = (bool) => {
        setVisible(bool);
    };
    //修改fields值的事件
    const _onChange = (value) => {
        if (onChange) {
            onChange(value);
        }
    };
    //清空fields的事件
    const _onClear = () => {
        if (onChange) {
            onChange(undefined);
            setLabel(undefined)
        }
    };

    return <Dropdown
        overlay={<MenuDropdown props={otherProps} value={value} onChange={_onChange} visibleDropdown={visibleDropdown}
                               setLabel={setLabel}/>}
        visible={visible} trigger={['click']} disabled={disabled}>
        <Input onChange={() => _onClear()} value={label} placeholder={disabled ? "" : "请选择地址"} allowClear
               onClick={() => setVisible(true)}/>
    </Dropdown>
};


export default forwardRef(AddressSelect);