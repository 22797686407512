import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import resetEnhancer from './enhancer/reset.js';
import reduxRequest from './middlewares/redux-request';
import globalReducer from './reducer.js';
//import messages from './messages';

const win = window;

export const history = createBrowserHistory();

const originalReducers = {router: connectRouter(history), global: globalReducer};

const reducer = combineReducers(originalReducers);

const middlewares = [reduxRequest, routerMiddleware(history)];

const storeEnhancers = compose(
  resetEnhancer,
  applyMiddleware(...middlewares),
  (win && win.devToolsExtension) ? win.devToolsExtension() : (f) => f
);

const store = createStore(reducer, {}, storeEnhancers);
store._reducers = originalReducers;
export default store;