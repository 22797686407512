import React, {cloneElement} from 'react';
import "../../view.css";

const FormTemplate = ({group, children}) => (
    <div style={{overflow: 'auto'}}>
        {
            Array.isArray(children) ? children.map((c, i) => (
                c && cloneElement(c, {group, key: group + i})
            )) : children && cloneElement(children, {group})
        }
    </div>
);

export default FormTemplate;