import {dispatchToProps, stateToProps} from "../tools";
import {connect} from "react-redux";
import {Button, Form} from "antd";
import React from "react";
import {PlusOutlined, MinusOutlined} from "@ant-design/icons";
import Label from "../label";


const {List, Item} = Form;

const DynamicFormItem = ({items, rulues, name, disable, initFiled}) => {

    return  <List name={name}>
        {(fields, { add, remove, move}) => {

            // const newFields = fields.length === 0 ? [{name: 0, key: 0, isListField: true, fieldKey: 0}] : fields;

            const newFields = (!initFiled && fields.length === 0) ?  [{name: 0, key: 0, isListField: true, fieldKey: 0}] : fields;

            return (
                <table className="ctrltable" width='100%'>
                    <thead>
						<tr>
							{(items instanceof Function ? items() : items ) .map((item, i) => (
								<th>
									<Label value={[item.props.label]}/>
								</th>
							))}
							{disable ?
								null : (
									<th style={{'width':'40px'}}>
										<Item>
											<PlusOutlined
												type="dashed"
												onClick={() => {
													if (fields.length === 0 && !initFiled){
														add();
													}
													add();
												}}
												block
											>
											</PlusOutlined>
										</Item>
									</th>
								)}
						</tr>
                    </thead>
					<tbody>
						{newFields.map((field,j) => (
							<tr>
								{(items instanceof Function ? items(j) : items).map((item, i) => (
									<td>
										<Item
											{...field}
											name={[field.name, ...item.props.name]}
											fieldKey={[field.fieldKey, ...item.props.name]}
											rules={rulues[i]}
											initialValue={item.props.value}
										>
											{item}
										</Item>
									</td>
								))}
								<td style={{'textAlign':'center'}}>
									<MinusOutlined
										onClick={() => {
											remove(field.name);
										}}

										disabled={disable}
									/>
									{/*
										<PlusOutlined
											type="dashed"
											onClick={() => {
												add();
											}}
											block
											disabled={disable}
										>
										</PlusOutlined>
									*/}
								</td>
							</tr>
						))}
					</tbody>
                </table>
            );
        }}
    </List>

}



const mapStateToProps = stateToProps(({state, ownProps}) => ({
}));

const mapDispatchToProps = dispatchToProps(({dispatch, ownProps}) => ({



}));


export default connect(mapStateToProps, mapDispatchToProps)(DynamicFormItem);
