import React from 'react';
import {connect} from 'react-redux';
import {stateToProps} from '../../utils/tools';

const A = ({name, className, onClick, children}) => (
    <a name={name} onClick={onClick} className={className}>{children}</a>
);

const mapStateToProps = stateToProps(({ownProps, group}) => ({
    name: ownProps.name + group
}));

export default connect(mapStateToProps)(A);