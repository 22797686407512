import React from 'react';
import {connect} from 'react-redux';
import {routeMap} from '../routes';
import {base} from '../utils';
import {restoreTab} from '../actions';

const BLOCK = {display: 'block'};
const NONE = {display: 'none'};
const ViewRouter = base(({tab, tabAct}) => {
	return <>
		{
			tab && tab.map(t => {
				return <div className="view-block" key={t.moId} style={t.moId == tabAct.moId ? BLOCK : NONE}>
					{React.createElement(routeMap.get(t.moPage.split("?")[0]).component, {group: t.moPage, active: t.moId == tabAct.moId, params: t.params})}
				</div>
			})
		}
	</>
});

const mapStateToProps = (state) => ({
	tab: state['global']['tab'],
	tabAct: state['global']['tabAct']
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	onInit: () => {
		dispatch(restoreTab(window.location.pathname));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewRouter);