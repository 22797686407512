import {
    ADD_FORM_INFO,
    CHANGE_DELETE_FILE,
    CHANGE_UPLOAD_FILE,
    CHANGEFORM,
    CHANGEOTHERFORM,
    DATA,
    DELETE_STATE,
    FAILURE,
    FORM,
    INIT_OPT_AREA,
    LOADING,
    MODEL_TYPE,
    NEED_INIT,
    OPTION_TOGGLE,
    OTHER_VALUE,
    PAGE_INFO,
    ROW_KEYS,
    SEARCH,
    SELECT,
    SET_TREE_SHOW,
    SETDICT,
    SETFIELD,
    SETOPTIONS,
    SETOTHERFIELD,
    TOGGLEMODAL,
    TREE_INFO
} from './actionTypes';

import store from '../store';
import sysConf from './sysConfig';

const {global, stateKey} = sysConf;

export const open = (name, modelData, formData, dataName) => ({
    type: TOGGLEMODAL,
    val: name,
    flag: true,
    modelData,
    data: formData,
    dataName: dataName || name
});

export const close = (name, dataName) => ({
    type: TOGGLEMODAL,
    val: name,
    dataName: dataName || name,
    flag: false
});

export const changeForm = (v) => ({
    type: CHANGEFORM,
    val: v
});

export const changeOtherForm = (v) => ({
    type: CHANGEOTHERFORM,
    val: v
})

export const loading = (name, isLoading) => ({
    type: LOADING,
    val: isLoading,
    name: name
});

export const data = d => {

    const f = name => {

        if (!(d instanceof Array) && typeof d === 'object') {
            const state = store.getState();
            const glState = state[global];
            const sk = glState[stateKey];
            d['filter'] = state[sk][name] ? state[sk][name]['filter'] : {};
        }

        return {type: DATA, val: d, name};
    };
    f.type = 'd';
    return f;
}

export const setData = (val, name) => ({
    type: DATA,
    val,
    name
});

export const failure = (error) => ({
    type: FAILURE,
    val: error
});

export const setField = (val) => ({
    type: SETFIELD,
    val
});

export const setOtherField = (p) => ({
    type: SETOTHERFIELD,
    val: p
});


export const setOptions = (o) => ({
    type: SETOPTIONS,
    val: o
})

//设置字典
export const setDict = (d) => ({
    type: SETDICT,
    setDict: d,
});

//打开窗口类型
export const modelType = (p) => ({
    type: MODEL_TYPE,
    modelType: p
});

//表格选中
export const rowKeys = (p) => ({
    type: ROW_KEYS,
    rowKeys: p,
});

//分页信息
export const pageInfo = (p) => ({
    type: PAGE_INFO,
    pageInfo: p,
});

export const select = (ids, name) => ({
    type: SELECT,
    ids,
    name,
});

export const setInit = (name, isInit) => ({
    type: NEED_INIT,
    name,
    isInit
});

//对初始化时数组形式处理为树状
export const setTreeData = (name, data, isSelect) => ({
    type: TREE_INFO,
    name,
    data,
    isSelect
});


export const treeInfo = (name, treeInfo, data, isSelect) => ({
    type: TREE_INFO,
    name,
    treeInfo,
    data,
    isSelect,
});

export const search = (name, search) => ({
    type: SEARCH,
    name,
    search
});

export const otherValue = (name, value) => ({
    type: OTHER_VALUE,
    name,
    value,
});

export const setForm = (name, value, resetValues) => ({
    type: FORM,
    name,
    value,
    resetValues
});

export const deleteOne = (keys, group) => ({
    type: DELETE_STATE,
    keys: [keys],
    group
});

export const deleteMore = (keys, group) => ({
    type: DELETE_STATE,
    keys,
    group
});

export const setTreeShow = () => ({
    type: SET_TREE_SHOW
});

export const optionToggle = () => ({
    type: OPTION_TOGGLE
});

export const initOptArea = (defaultheight, minheight) => ({
    type: INIT_OPT_AREA,
    dheight: defaultheight,
    mheight: minheight
});

export const addDeleteFiles = (name, file, path) => ({
    type: CHANGE_DELETE_FILE,
    name,
    file,
    path
});

export const addUploadFiles = (name, file, path) => ({
    type: CHANGE_UPLOAD_FILE,
    name,
    path,
    file,
    isAdd: true
});

export const removeUploadFiles = (name, file, path) => ({
    type: CHANGE_UPLOAD_FILE,
    name,
    path,
    file,
    isAdd: false
});

export const addFormInfo = (name, info) => ({
    type: ADD_FORM_INFO,
    name,
    info
});

export const customTipShow = (info) => ({
	type: 'customTipShow',
	info: info
});

export const customTipHide = () => ({
	type: 'customTipHide'
});